import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import {Form, Input, Select} from "antd";
import FileInput from "./FileInput";
import React, {useState} from "react";

export default function PoetryInput({field, prefix, fullPrefix, form}: any) {
    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)
    const {extData, isRequired} = field || {};
    const [options, setOptions]: any = useState([]);

    const handleSearch = (keyword: string) => {
        if (!keyword) return;
        jsonRequest.post(`/api/request`, {
            path: `/v1/contents/tags`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode: 'Poetics', type: 'content', keyword}
        })
            .then((resp: any) => resp.json())
            .then(({data}: any) => {
                data && setOptions(data.map((e: any) => ({
                    label: e.fullName,
                    value: e.id,
                    ...e,
                })))
            })
    }

    const onSelectChange = (value: any) => {
        console.log(value)
        form.setFieldValue([...fullPrefix, 'term_poetics'], value.map((e: any) => {
            const {id, contentName} = options.find((o: any) => o.id === e);
            return {id, name: contentName};
        }));
    }

    return <>
        <Form.Item label={`经穴歌`} name={[...prefix, 'description']}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入歌诀"/>
        </Form.Item>
        <FileInput form={form} labelPre="音频" prefix={[...prefix, 'audio']} pathName='url'
                   fullPrefix={[...fullPrefix, 'audio']} type="audio" field={field}/>
        <Form.Item label={`音频时长（秒）`} name={[...prefix, 'audio', "duration"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入音频时长"/>
        </Form.Item>
        <FileInput form={form} labelPre="字幕" prefix={[...prefix, 'audio']} 
                   fullPrefix={[...fullPrefix, 'audio']} type="other" field={field} namePre="subtitle"/>
        <Form.Item label='相关歌赋' name={[...prefix, 'select_term_poetics']}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Select
                showSearch
                onChange={onSelectChange}
                mode="multiple"
                placeholder={extData?.inputPrompt}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearch}
                options={options}
            />
        </Form.Item>
        <Form.Item name={[...prefix, 'term_poetics']} hidden><Input/></Form.Item>
    </>
}