import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectJsonRequest, selectCurrentTenant } from "../components/global"
import { EditOutlined, KeyOutlined } from "@ant-design/icons"
import { Table, Switch, Button } from "antd"
import { Link } from "react-router-dom"


export default function ApplicationList() {

    const [data, setData]: any = useState([])
    const [loading, setLoading] = useState(true)
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)

    useEffect(() => {
        reload()
        return () => {
        }
    }, [currentTenant])

    const reload = () => {
        if (!currentTenant) return;

        jsonRequest.post("/api/request/api/application", { method: "get", application_key: "zy:services::keys", tenant_meta: currentTenant, data: {} })
            .then(resp => resp.json())
            .then(({ data }) => {
                setLoading(false)
                setData(data?.data)
            })
    }

    return <div className="page application-table">
        <h1>应用列表</h1>

        <div className="content">

            <Table dataSource={data} loading={loading} pagination={false}>
                <Table.Column align="left" title="ID" dataIndex="id" key="id" />
                <Table.Column align="left" title="名称" dataIndex="name" key="name" />
                <Table.Column align="left" title="Application Key" dataIndex="key" key="key" />
                <Table.Column align="left" title="HOST" dataIndex="host" key="host" />
                <Table.Column align="center" title="操作" render={(text, item: any) => {
                    return <>
                        <Link to={`/key/keys/${item.key}`} state={{ application: item }}><KeyOutlined /> 密钥列表</Link>
                    </>
                }} />
            </Table>
        </div>
    </div>
}