import { Outlet } from "react-router-dom"
import SideMenu from "./components/side_menu"
import { useDispatch } from "react-redux"
import { NavActive } from "../components/global"
import { useEffect } from "react"


function Tool(params: any) {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(NavActive("TOOL"))

    }, [])

    return <div className="module module-tool">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content">
            <Outlet />
        </div>
    </div>

}

export default Tool