import {ActionType, ProTable} from "@ant-design/pro-components";
import {Button, Form, message, Modal, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../components/global";
import React, {useEffect, useRef, useState} from "react";
import GlobalWrap from "../../components/globalWrap";
import CategoryForm from "./components/CategoryForm";
import CategoryDetailForm from "./components/CategoryDetailForm";
import {handleContentValues} from "../content/components/ContentFormat";

export default function Category(params: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [form] = Form.useForm();
    const tableRef = useRef<ActionType>();

    const [tenants, setTenants] = useState<any>([]);
    const [groups, setGroups] = useState<any>([]);

    useEffect(() => {
        getTenants();
        tableRef.current?.reload();
    }, [currentTenant]);

    const getGroups = (systemCode: string) => {
        if (!systemCode) return;
        jsonRequest.post("/api/request", {
            path: "/v1/content/cates/groups",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode},
        })
            .then((resp: any) => resp.json())
            .then(({data}) => {
                setGroups(data)
            })
    }

    const onValuesChange = ({systemCode}: any) => {
        if (systemCode) getGroups(systemCode);
    }

    const getTenants = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/systems/tenant",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        })
            .then((resp: any) => resp.json())
            .then(({data}) => {
                setTenants(data)
            })
    }

    const columns: any[] = [
        {
            title: '排序号',
            dataIndex: 'index',
            search: false,
            align: 'center',
        },
        {
            title: '名称',
            dataIndex: 'fullCateName',
            align: 'left',
            search: false,
        },
        {
            title: '层级',
            dataIndex: 'level',
            align: 'center',
            search: false,
        },
        {
            title: '所属体系',
            dataIndex: 'systemName',
            align: 'center',
            search: false,
        },
        {
            title: '所属体系',
            dataIndex: 'systemCode',
            align: 'center',
            hideInTable: true,
            renderFormItem: () => <Select allowClear
                                          options={tenants.map((e: any) => ({
                                              value: e.systemCode,
                                              label: e.systemName
                                          }))}/>
        },
        {
            title: '分组',
            dataIndex: 'fieldId',
            hideInTable: true,
            search: groups.length > 0,
            renderFormItem: () => <Select allowClear
                                          options={groups.map((e: any) => ({
                                              value: e.fieldId,
                                              label: e.groupName
                                          }))}/>
        },
        {
            title: '关键词',
            dataIndex: 'keyword',
            hideInTable: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            search: false,
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            key: 'option',
            search: false,
            align: 'center',
            width: 400,
            render: (_: any, record: any) => <>
                <Button type="link" onClick={() => handleAddOrEdit({}, record)}>添加子分类</Button>
                {record.level > 1 && <Button type="link" onClick={() => handleAddOrEdit({}, {
                    id: record.parentId, fieldId: record.fieldId, fieldName: record.fieldName,
                    cateName: record.parentName, systemCode: record.systemCode,
                })}>添加同级分类</Button>}
                <Button type="link" onClick={() => handleAddOrEdit(record)}>修改</Button>
                <Button type="link" onClick={() => handleDetailEdit(record)}>详情编辑</Button>
            </>,
        }
    ]

    const handleDetailEdit = (detail: any) => {
        Modal.confirm({
            width: 1000,
            title: <span>分类详情编辑</span>,
            cancelText: '关闭',
            okText: '保存',
            content: <GlobalWrap>
                <CategoryDetailForm form={form} tenants={tenants} detail={detail}/>
            </GlobalWrap>,
            onOk: (close) => {
                form.validateFields()
                    .then(async (values) => {
                        const {medias, customMedias, ownerId, ownerType} = values;
                        handleContentValues(values)
                        console.log(values)
                        const {data} = await jsonRequest.post("/api/request", {
                            path: `/v1/contents/medias`,
                            method: "post",
                            application_key: "zy:application::knowledge-foundation",
                            tenant_meta: currentTenant,
                            data: {ownerId, ownerType, medias: [...medias || [], ...customMedias || []]},
                        }).then(resp => resp.json())
                        if (!data.error) {
                            // @ts-ignore
                            tableRef.current.reload();
                            message.success(`编辑成功`)
                            close();
                        } else {
                            message.error(data.error);
                        }
                    })
                    .catch(_ => _)
                return false
            },
            onCancel: (close) => {
                form.resetFields();
                // @ts-ignore
                tableRef.current.reload();
                close();
            },
        });
    }

    const handleAddOrEdit = (detail: any, parent: any = {}) => {
        Modal.confirm({
            width: 500,
            title: <span>{parent.id ? '分类' : '分组'} - {detail?.id ? '编辑' : '新增'}</span>,
            content: <GlobalWrap>
                <CategoryForm form={form} tenants={tenants} parent={parent}
                              detail={{
                                  parentId: detail.parentId || parent?.id || 0,
                                  fieldId: detail.fieldId || parent.fieldId,
                                  groupName: detail.fieldName || parent.fieldName,
                                  parentName: detail.parentName || parent.cateName,
                                  systemCode: detail.systemCode || parent.systemCode,
                                  ...detail
                              }}/>
            </GlobalWrap>,
            onOk: (close) => {
                form.validateFields()
                    .then(async (values) => {
                        const {data} = await jsonRequest.post("/api/request", {
                            path: `/v1/content/cates`,
                            method: detail.id ? "put" : "post",
                            application_key: "zy:application::knowledge-foundation",
                            tenant_meta: currentTenant,
                            data: values,
                        }).then(resp => resp.json())
                        if (!data.error && !data.message) {
                            // @ts-ignore
                            tableRef.current.reload();
                            message.success(`${detail?.id ? '编辑' : '新增'}成功`)
                            close();
                        } else {
                            message.error(data.error || data.message);
                        }
                    })
                    .catch(_ => _)
                return false
            },
            onCancel: (close) => {
                form.resetFields();
                close();
            },
        })
    }

    const request = async (params: any, sort: any, filter: any) => {
        const {current, pageSize, ...others} = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/v1/content/cates/paged",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {
                ...others,
                pageNumber: current,
                pageSize,
            },
        }).then((resp: any) => resp.json())
        const {data} = result;
        return {data: data.list, success: result.code === 0, total: data.total};
    }

    return (
        <>
            <h1>分类维护</h1>
            <ProTable
                rowKey="id"
                actionRef={tableRef}
                cardBordered
                columns={columns}
                form={{onValuesChange}}
                request={request}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                search={{span: 6, defaultCollapsed: false}}
                toolBarRender={() => [<Button
                    key="button"
                    icon={<PlusOutlined/>}
                    onClick={() => handleAddOrEdit({})}
                    type="primary"
                >
                    新增分组
                </Button>]}
            />
        </>
    )
}