import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {NavActive} from "../components/global";
import {Outlet} from "react-router-dom";
import SideMenu from "./components/side_menu";

function Knowledge(params: any) {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(NavActive("CONTENT"))

    }, [])

    return <div className="module module-mall">
        <div className="side-menu">
            <SideMenu />
        </div>
        <div className="content" style={{overflowY: 'scroll'}}>
            <Outlet />
        </div>
    </div>

}

export default Knowledge