import {Collapse} from "antd";
import {useEffect, useState} from "react";

export default function MediaCollapse({items, defaultActiveKey, elementId, defaultOpen, index, ...others}: any) {
    const [activeKey, setActiveKey]: any = useState(defaultOpen ? defaultActiveKey : []);

    useEffect(() => {
        if (defaultOpen) return;
        window.addEventListener(`content-anchor-click:#${elementId}`, (e) => {
            setActiveKey([defaultActiveKey])
        })
        return () => {
            window.removeEventListener(`content-anchor-click:#${elementId}`, (e) => {
            })
        }
    }, []);

    const onChange = (key: any) => setActiveKey(key)

    return <Collapse activeKey={activeKey} {...others} onChange={onChange}
                     items={items && items(activeKey.length > 0)}/>
}