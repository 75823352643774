import {Form, Input, Select} from "antd";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest, selectUser} from "../../../components/global";

export default function LinkForm({form, link}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [tenants, setTenants] = useState<any>([]);
    const [options, setOptions]: any = useState([]);
    const [chapterOptions, setChapterOptions]: any = useState([]);

    useEffect(() => {
        form.resetFields();
        getTenants();
    }, [])

    const getTenants = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/systems/tenant",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        })
            .then((resp: any) => resp.json())
            .then(({data}) => setTenants(data))
    }

    const onSystemCodeChange = (v: any) => {
        form.setFieldValue('source', undefined)
        form.setFieldValue('chapter', undefined)
        setOptions([]);
        setChapterOptions([]);
    }

    const handleSearch = (keyword: string) => {
        if (!keyword) return;
        const systemCode = form.getFieldValue('systemCode')
        jsonRequest.post(`/api/request`, {
            path: `/v1/contents/tags`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode, type: 'content', keyword}
        })
            .then((resp: any) => resp.json())
            .then(({data}: any) => {
                data && setOptions(data.map((e: any) => ({
                    label: `${e.fullName || e.contentName}${e.otherStr ? `-${e.otherStr}` : ''}`,
                    value: e.id,
                    ...e,
                })))
            })
    }

    const onSourceChange = ({value}: any = {}) => {
        if (!value) {
            form.setFieldValue('chapter', undefined);
            setChapterOptions([]);
            return;
        }
        const systemCode = form.getFieldValue('systemCode')
        jsonRequest.post(`/api/request`, {
            path: `/v1/contents/chapters`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode, contentId: value}
        })
            .then((resp: any) => resp.json())
            .then(({data}: any) => {
                data && setChapterOptions(data.map((e: any) => ({
                    label: e.name,
                    value: e.id,
                    ...e,
                })))
            })
    }

    return <Form form={form} size='middle' labelCol={{span: 5}} wrapperCol={{span: 16}} initialValues={link}>
        <Form.Item label="所属体系" name="systemCode" rules={[{required: true, message: '不能为空'}]}>
            <Select onChange={onSystemCodeChange}
                    options={tenants.map((e: any) => ({value: e.systemCode, label: e.systemName}))}/>
        </Form.Item>
        <Form.Item label="请选择内容" name="source" rules={[{required: true, message: '不能为空'}]}>
            <Select
                showSearch
                defaultActiveFirstOption={false}
                labelInValue={true}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearch}
                options={options}
                onChange={onSourceChange}
                allowClear
            />
        </Form.Item>
        <Form.Item noStyle dependencies={['source']}>
            {({getFieldValue}) => getFieldValue('source') &&
                <Form.Item label=" " name='chapter' colon={false}>
                    <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        labelInValue={true}
                        suffixIcon={null}
                        filterOption={(input, option: any) => (option?.label ?? '').includes(input)}
                        options={chapterOptions}
                        allowClear
                    />
                </Form.Item>}
        </Form.Item>
        <Form.Item label='关联信息' dependencies={['source', 'chapter']}>
            {({getFieldValue}) => {
                const sourceName = getFieldValue('source')?.label || '';
                const chapterName = getFieldValue('chapter')?.label || "";
                return <span>{sourceName} {chapterName && '-'} {chapterName}</span>
            }}
        </Form.Item>
        <Form.Item label="链接文字" name='title' rules={[{required: true, message: '不能为空'}]}>
            <Input placeholder="请输入链接文字"/>
        </Form.Item>
        <Form.Item label="业务标识" name='start' help='如：视频播放起始时间节点'>
            <Input placeholder="请输入业务标识，由具体业务决定"/>
        </Form.Item>
    </Form>
}