
import { CrownTwoTone } from "@ant-design/icons"
import { Badge, Avatar, Tooltip, message } from "antd"
import "./OwnerAvatar.css"


export default function OwnerAvatar(props: { avatar: string, id: string, size?: number, nickname: string }) {
    // const { onlineUsers, id = null, size = 40, avatar = "", onlyAvatar = false, disabled = false } = props
    const { id = null, size = 40, avatar = "" } = props
    // const onlineUserList = onlineUsers.online_user_list || []
    // const online = !!onlineUserList.find((online) => online?.user?.id?.toString() === id?.toString())

    // return <div className="user-avatar-wrap" onClick={() => true && id && props.show(id)} {...props}>
    //     <Badge className="user-avatar" count={false ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: size * 0.4 }}>
    //         <Avatar src={avatar} className={`${false ? "online" : ""}`} size={size} />
    //     </Badge>
    //     {!onlyAvatar && <span className="nickname" style={{ maxWidth: size + 4 }}>{props.nickname || id}</span>}
    // </div>


    return <div className="user-avatar-wrap" {...props} onClick={() => navigator.clipboard.writeText(id!)
        .then(() => {
            message.success({
                content: '持有人ID已复制到剪切板中',
                duration: 3
            })
        })
    }>
        <Badge className="user-avatar" count={false ? <CrownTwoTone twoToneColor="#eb2f96" rotate={45} /> : null} style={{ fontSize: size * 0.4 }}>
            <Avatar src={avatar} className={`${false ? "online" : ""}`} size={size} />
        </Badge>
        <Tooltip title={`${props.nickname || id} @${id}`}><span className="nickname" style={{ maxWidth: size + 4 }}>{props.nickname || id}</span></Tooltip>
        <span className="nickname">@{id}</span>
    </div>
}
