import {Form, Input, InputNumber, Switch, Image} from "antd";
import UploadComponent from "../../../components/UploadComponent";
import {PlusOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {selectCurrentTenant} from "../../../components/global";
import {useEffect, useState} from "react";

declare var window: any;

export default function ProductSkuForm({tabKey, form, uId}: any) {
    const currentTenant: any = useSelector(selectCurrentTenant)
    const [fileList, setFileList]: any = useState([]);
    const [previewOpen, setPreviewOpen]: any = useState(false);
    const [previewImage, setPreviewImage]: any = useState('');

    useEffect(() => {
        const images = form.getFieldValue(["productSku", tabKey, "images"])
        if (images && images.length) {
            const files = images.map((e: any) => ({url: e.objName, uid: -(images.indexOf(e) - 1), ...e}))
            form.setFieldValue(["productSku", tabKey, "imageFiles"], files)
            setFileList(files)
        } else {
            setFileList([])
        }
    }, [uId]);

    const handleUploadSuccess = (fileList: any) => {
        const images = fileList.map((e: any) => ({
            ...e,
            url: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`,
            objName: e.url.startsWith('http') ? e.url : `${window.ATTACHMENT_URL}/api/oss/${e.url}`
        }));
        setFileList(images);
        // const url = 
        form.setFieldValue(["productSku", tabKey, "images"], images)
        form.setFieldValue(["productSku", tabKey, "imageFiles"], fileList)
    }

    const handlePreview = async (file: any) => {
        setPreviewImage(file.url);
        setPreviewOpen(true);
    };

    return (
        <>
            <Form.Item label="商品SKUID" name={["productSku", tabKey, "id"]}
                       style={{display: "none"}}>
                <Input disabled/>
            </Form.Item>
            <Form.Item label="商品SKU名称" name={["productSku", tabKey, "name"]}
                       rules={[{required: true, message: '不能为空'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="商品SKU价格" name={["productSku", tabKey, "productPrice"]} initialValue={1}
                       rules={[{required: true, message: '不能为空'}]}>
                <InputNumber/>
            </Form.Item>
            <Form.Item label="商品SKU库存" name={["productSku", tabKey, "productStock"]} initialValue={1}
                       rules={[{required: true, message: '不能为空'}]}>
                <InputNumber/>
            </Form.Item>
            <Form.Item shouldUpdate={(p, c) => p.type !== c.type} noStyle>
                {({getFieldValue}) => (getFieldValue('type') == 1 ? (
                    <Form.Item label="回调参数" name={["productSku", tabKey, "externalCallbackParamsStr"]}
                               labelCol={{span: 6}}
                               rules={[{required: true, message: '不能为空'}]}>
                        <Input/>
                    </Form.Item>
                ) : null)}
            </Form.Item>
            <Form.Item label="是否启用" name={["productSku", tabKey, "status"]} valuePropName="checked"
                       initialValue={false}>
                <Switch/>
            </Form.Item>
            <Form.Item label="商品SKU排序" name={["productSku", tabKey, "sort"]} initialValue={0}>
                <InputNumber/>
            </Form.Item>
            <Form.Item label="商品SKU图标" name={["productSku", tabKey, "imageFiles"]}
                       rules={[{required: true, message: '请上传图片'}]}>
                <UploadComponent path={[currentTenant, "product"].join("/")}
                                 listType="picture-card"
                                 value={fileList}
                                 onPreview={handlePreview}
                                 onChange={(fileList: any) => handleUploadSuccess(fileList)}>
                    <div>
                        <PlusOutlined/>
                        <div style={{marginTop: 8}}>拖到这里</div>
                    </div>
                </UploadComponent>
                {previewImage && (
                    <Image
                        wrapperStyle={{display: 'none'}}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </Form.Item>
            <Form.Item label="商品SKU图标" name={["productSku", tabKey, "images"]}
                       style={{display: "none"}}>
                <Input disabled/>
            </Form.Item>
        </>
    )
}