import {Form, Input, Select} from "antd";

export default function ContentAuditForm({form, auditStates, detail}: any) {
    
    form.setFieldsValue(detail)

    return <Form form={form} size="small" labelCol={{span: 6}} wrapperCol={{span: 16}}
                 autoComplete="off">
        <Form.Item name='id' hidden><Input/></Form.Item>
        <Form.Item label="审核状态" name="auditState" rules={[{required: true, message: '不能为空'}]}>
            <Select
                options={auditStates?.map((e: any) => ({
                    value: e.enumKey,
                    label: e.enumName
                }))}/>
        </Form.Item>
    </Form>
}