import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import React, {useEffect, useState} from "react";
import {Checkbox, Form, Input, InputNumber, Select} from "antd";
import {useWatch} from "antd/lib/form/Form";

export default function InsertForm({form, media}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [mediaTypes, setMediaTypes]: any = useState([])
    const [viewTypes, setViewTypes]: any = useState([])
    const {mediaId, parentName, fieldKey} = media;
    const mediaType = useWatch('mediaType', form)

    useEffect(() => {
        form.resetFields();
        getMediaTypes();
        getViewType();
    }, []);

    const getMediaTypes = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type: 'media_type'},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setMediaTypes(data)
            })
    }

    const getViewType = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type: 'view_type'},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setViewTypes(data)
            })
    }

    return <Form form={form} size='middle' labelCol={{span: 6}} wrapperCol={{span: 16}} initialValues={media}>
        <Form.Item label='上级字段名称'>{parentName || '无'}</Form.Item>
        <Form.Item label='字段Key'>{fieldKey || '无'}</Form.Item>
        <Form.Item label="媒体类型" name='mediaType'
                   rules={[{required: true, message: '不能为空'}]}>
            <Select disabled={!!mediaId} options={mediaTypes.map((e: any) => ({value: e.enumKey, label: e.enumName}))}/>
        </Form.Item>
        <Form.Item name='mediaId' hidden><Input/></Form.Item>
        <Form.Item label="字段标题" name='mediaName' initialValue={''}>
            <Input/>
        </Form.Item>
        <Form.Item label=' ' name='hideTitle' colon={false} valuePropName="checked">
            <Checkbox>隐藏标题（前端）</Checkbox>
        </Form.Item>
        {(mediaType === 'rich_text' || mediaType === 'string') &&
            <Form.Item label=' ' name='foldStatus' colon={false} valuePropName="checked">
                <Checkbox>折叠时显示内容概述</Checkbox>
            </Form.Item>}
        <Form.Item name='source' hidden initialValue={'new'}><Input/></Form.Item>
        <Form.Item label="排序号" name='index' initialValue={1}
                   rules={[{required: true, message: '不能为空'}]}>
            <InputNumber/>
        </Form.Item>
        <Form.Item label="下级显示方式" name='viewType' initialValue='default'
                   rules={[{required: true, message: '不能为空'}]}>
            <Select options={viewTypes.map((e: any) => ({value: e.enumKey, label: e.enumName}))}/>
        </Form.Item>
    </Form>
}