import { GiftOutlined, ShoppingCartOutlined, TruckOutlined, WifiOutlined } from "@ant-design/icons";

export default function shippingType(type: Number) {
    switch (type) {
        case 0: return <span><TruckOutlined /> 快递</span>
        case 1: return <span><ShoppingCartOutlined /> 自取</span>
        case 2: return <span><WifiOutlined /> 网络</span>

        default:
            break;
    }
}