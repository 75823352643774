import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import {Button, Form, Modal, Popconfirm, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusCircleTwoTone} from "@ant-design/icons";
import GlobalWrap from "../../../components/globalWrap";
import ReleaseChannelForm from "./components/ReleaseChannelForm";
import logisticsTypes from "./components/logisticsTypes";

export default function ReleaseChannel(params: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)

    const [releaseChannels, setReleaseChannels]: any = useState([])
    const [channelForm] = Form.useForm()

    useEffect(() => getData(), []);

    const getData = (queryData: any = {}) => {
        jsonRequest.post("/api/request", {
            path: "/v1/releaseChannel/",
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: queryData,
        })
            .then(resp => resp.json())
            .then(({data}: any) => {
                setReleaseChannels(data)
            })
    }

    const columns = [
        {
            title: '物流名称',
            dataIndex: 'channelName',
            key: 'channelName'
        },
        {
            title: '物流类型',
            dataIndex: 'logisticsType',
            key: 'logisticsType',
            render: (text: any) => <span>{logisticsTypes[text]}</span>
        },
        {
            title: '代理费',
            dataIndex: 'agencyFee',
            key: 'agencyFee'
        },
        {
            title: '操作',
            key: 'operation',
            render: (_: any, record: any) => (
                <>
                    <Button type='link' size='small' onClick={() => handleUpdateOrInsert(record)}>
                        <EditOutlined/>
                    </Button>
                    <Popconfirm title="确定删除？" okText="是" cancelText="否"
                                onConfirm={() => deleteProductCategory(record.id)}>
                        <Button type='link' size='small' danger><DeleteOutlined/></Button>
                    </Popconfirm>
                </>
            ),
        }
    ];

    const deleteProductCategory = async (id: any) => {
        await jsonRequest.post("/api/request", {
            path: `/v1/releaseChannel/${id}`,
            method: "delete",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {id},
        })
        getData()
    }

    const handleUpdateOrInsert = (channel: any) => {
        Modal.confirm({
            width: 500,
            title: !!channel.id ? `物流编辑 - ${channel.channelName}` : "物流新增",
            content: <GlobalWrap><ReleaseChannelForm form={channelForm} channel={channel}/></GlobalWrap>,
            onOk: async (close) => {
                let values = await channelForm.validateFields()

                if (!!channel?.id)
                    await jsonRequest.post("/api/request", {
                        path: `/v1/releaseChannel/update/${channel.id}`,
                        method: "post",
                        application_key: "zy:services::mall",
                        tenant_meta: currentTenant,
                        data: {id: channel.id, ...values}
                    })
                else
                    await jsonRequest.post("/api/request", {
                        path: `/v1/releaseChannel/`,
                        method: "post",
                        application_key: "zy:services::mall",
                        tenant_meta: currentTenant,
                        data: {...values}
                    })

                channelForm.resetFields();
                getData();
                close()
                return false
            },
            onCancel: (close) => {
                channelForm.resetFields();
                close();
            }
        })
    }

    return (
        <div className='page'>
            <h1 style={{display: "flex", alignItems: "center"}}>
                物流管理
                <Button onClick={() => handleUpdateOrInsert({})}
                        type="link" size="small" icon={<PlusCircleTwoTone/>}>添加物流方式</Button>
            </h1>
            <div className='content'>
                <Table pagination={false} dataSource={releaseChannels} columns={columns}/>
            </div>
        </div>
    )
}