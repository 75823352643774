import { Form, Input, Select, InputNumber, TreeSelect } from "antd";
import constructCategoryTree from "./constructCategoryTree";
export default function ProductCategoryForm({ category, form, categoryTree }: any) {
    form.setFieldsValue({ ...category })

    return <div className="order-shipping-form">
        <Form form={form} size="small" name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="分类名" name="categoryName" rules={[{ required: true, message: '不能为空' }]}>
                <Input />
            </Form.Item>
            <Form.Item label="上级分类" name="pid">
                <TreeSelect allowClear showSearch treeDefaultExpandAll treeData={constructCategoryTree(categoryTree)} />
            </Form.Item>
            <Form.Item label="分类排序" name="sort" initialValue={0} rules={[{ required: true, message: '不能为空' }]}>
                <InputNumber />
            </Form.Item>
        </Form>
    </div>
}