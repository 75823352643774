import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import {
    selectCurrentTenant,
    selectJsonRequest,
    selectNavActiveKey,
    selectUser,
    setTenant,
    signOut
} from "./components/global";
import { Layout, Menu, Button, Modal, Form, message, Input, Select, Tag } from 'antd'
import { AlertTwoTone, CustomerServiceOutlined, KeyOutlined, ReadOutlined, SettingTwoTone, ToolOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useLocalStorageState from "use-local-storage-state";

const { Header } = Layout;

function Root(props: any) {
    const [editPasswordForm] = Form.useForm();

    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const navActiveKey = useSelector(selectNavActiveKey)
    const jsonClient = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [persistentCurrentTenant, setCurrentTenant] = useLocalStorageState('current-tenant', undefined)

    const [grantedTenants, setGrantedTenants] = useState([])

    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.setDefault('UTC')

    useEffect(() => {
        // 获取授权的租户
        jsonClient.get("/api/user/granted_tenant", {})
            .then(resp => resp.json())
            .then(({ data }: any) => {
                setGrantedTenants(data || [])
                handleChangeTenant(persistentCurrentTenant || data?.[0]?.meta, false)
            })
            .catch(any => console.log(any))
        return () => {

        }
    }, [jsonClient])

    if (!user)
        return <Navigate to="/sign_in" />

    const logOut = () => {
        dispatch(signOut())
        return <Navigate to="/sign_in" />
    }

    const changePasswordConfirm = () => {
        const handleEditPasswordSubmit = (values: any, modalClose: () => void) => {
            jsonClient.put("/api/user/password", values)
                .then((response: { json: () => any; }) => response.json())
                .then(({ data, message: msg }: any) => {
                    if (!!data && !!data.success) {
                        message.success({
                            content: <span>密码修改成功，建议<a href="#/" onClick={() => {
                                logOut();
                                message.destroy()
                            }}>重新登录</a></span>, duration: 5
                        })
                        modalClose()
                    } else
                        message.warning(msg)
                })
        }

        Modal.confirm({
            title: '修改密码',
            content: <Form form={editPasswordForm} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                autoComplete="off">
                <Form.Item label="原密码" name="origin_password" rules={[{ required: true, message: '原始密码能为空' }]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item label="新密码" name="new_password" rules={[{ required: true, message: '新密码不能为空' }]}>
                    <Input.Password />
                </Form.Item>
            </Form>,
            onOk: (close: any) => {
                editPasswordForm
                    .validateFields()
                    .then((values: any) => {
                        editPasswordForm.resetFields();
                        handleEditPasswordSubmit(values, close);
                    })
                    .catch((info: any) => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    const handleChangeTenant = (v: any, persistent: boolean = true) => {
        dispatch(setTenant(v))
        if (persistent || !persistentCurrentTenant)
            setCurrentTenant(v)
    }

    return <div className="App">
        <main role="main" className="container">
            <div style={{ padding: "5px" }}>
                <Layout className="layout">
                    <Header style={{ background: "#fff", padding: 0, display: "flex" }}>
                        <div className="logo">
                        </div>

                        <Menu theme="light" mode="horizontal" selectedKeys={[navActiveKey]}
                            style={{ border: "none", flexGrow: 1 }}>
                            <Menu.Item key="MALL" icon={<AlertTwoTone />}>
                                <Link to="/mall/order">积分商城</Link>
                            </Menu.Item>
                            <Menu.Item key="CONTENT" icon={<ReadOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/knowledge">内容库</Link>
                            </Menu.Item>
                            <Menu.Item key="FINANCE" icon={<AlertTwoTone />}>
                                <Link to="/finance/coin/code-table">财务</Link>
                            </Menu.Item>
                            <Menu.Item key="KEY" icon={<KeyOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/key/keys">密钥服务</Link>
                            </Menu.Item>
                            <Menu.Item key="CUSTOMER-SERVICE" icon={<CustomerServiceOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/customer-service/chat">客服</Link>
                            </Menu.Item>
                            <Menu.Item key="OPERATOR" icon={<SettingTwoTone />}>
                                <Link to="/operator/tenant/index">运维</Link>
                            </Menu.Item>
                            <Menu.Item key="TOOL" icon={<ToolOutlined style={{ color: "#1677ff" }} />}>
                                <Link to="/tool/jose/signature">工具</Link>
                            </Menu.Item>
                        </Menu>
                        <div className="side">
                            <Select value={currentTenant} style={{ minWidth: 150 }}
                                onChange={(v) => handleChangeTenant(v)}>
                                {grantedTenants.map(((tenant: any) => (
                                    <Select.Option value={tenant.meta} key={tenant.meta}><Tag>租户</Tag> {tenant.name}
                                    </Select.Option>
                                )))}
                            </Select>
                            <span style={{ padding: "4px 15px" }}> {user.name}</span>
                            <Button type="link" onClick={() => changePasswordConfirm()}>修改密码</Button>
                            <Button type="link" onClick={() => logOut()}>退出</Button>
                        </div>
                    </Header>
                </Layout>
            </div>

            <Outlet />
        </main>
    </div>
}

export default Root