import OssPath from "../../components/oss_path";
import Sha256Encode from "../../components/sha256";

export default async function ContentImageUploader(jsonRequest: any, currentTenant: any, image: any) {
    const {data} = await jsonRequest.post("/api/request", {
        path: `/v1/aliyun/oss`,
        method: "get",
        application_key: "zy:application::knowledge-foundation",
        tenant_meta: currentTenant,
        data: {type: 'picture'}
    })
        .then((resp: any) => resp.json())

    const file: any = await handleImgFile(image, data)

    const ossPath = await OssPath(jsonRequest, currentTenant);
    var formData = new FormData()
    formData.append('key', file.url)
    formData.append('policy', data.policy)
    formData.append('OSSAccessKeyId', data.keyId)
    formData.append('Signature', data.signature)
    formData.append('success_action_status', '200')
    formData.append('file', file)

    await fetch(data.host, {method: 'POST', body: formData, mode: 'cors'})
    return `${ossPath}${file.url}`;
}

const handleImgFile = async (file: any, OSSData: any) => {
    const file_content = await file.arrayBuffer()
    const file_name = await Sha256Encode(file_content)

    file.url = [OSSData?.dir, file_name].join("/")
    return file;
}