import {Form, Input, Select} from "antd";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import OrderedMedia from "./OrderedMedia";

export default function DosageInput({prefix, index, form, listPathName}: any) {
    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)
    const [options, setOptions]: any = useState([])

    const handleSearch = (keyword: string) => {
        if (!keyword) return;
        jsonRequest.post(`/api/request`, {
            path: `/v1/contents/tags`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode: 'herb', type: 'content', keyword}
        })
            .then((resp: any) => resp.json())
            .then(({data}: any) => {
                data && setOptions(data.map((e: any) => ({
                    label: e.contentName,
                    value: e.id,
                })))
            })
    }

    return <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <Form.Item name={[...prefix, 'title']} label={`中药名称`}
                   rules={[{required: true, message: '不能为空'}]}><Input/></Form.Item>
        <Form.Item name={[...prefix, 'herbal']} label='中药标签'>
            <Select
                showSearch
                labelInValue={true}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearch}
                options={options}
            />
        </Form.Item>
        <Form.Item name={[...prefix, 'ancient_usage']} label={`用法(古代)`}><Input/></Form.Item>
        <Form.Item name={[...prefix, 'modern_usage']} label={`用法(现代)`}><Input/></Form.Item>
        <Form.Item name={[...prefix, 'ancient_dosage']} label={`古代剂量`}><Input/></Form.Item>
        <Form.Item name={[...prefix, 'modern_dosage']} label={`现代剂量`}><Input/></Form.Item>
    </OrderedMedia>
}