import {Form, Input, Switch} from "antd";
import CustomQuill from "../../../components/CustomQuill";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import ContentImageUploader from "../ContentImageUploader";
import React, {useState} from "react";

const {TextArea} = Input;

export default function RichTextInput({field, prefix}: any) {
    const {extData, isRequired} = field || {};
    const [showCode, setShowCode]: any = useState(false);

    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)

    const imgUploadApi = async (fd: any) => {
        return await ContentImageUploader(jsonRequest, currentTenant, fd.get('file'));
    }

    return <>
        <Form.Item label='查看源码'>
            <Switch defaultChecked={showCode} onChange={(v) => setShowCode(v)}/>
        </Form.Item>
        <Form.Item name={[...prefix, 'description']} wrapperCol={{span: 23}} initialValue={''}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            {showCode ? <TextArea rows={3}/> :
                <CustomQuill imgUploadApi={imgUploadApi} placeholder={extData?.inputPrompt}/>}
        </Form.Item>
    </>
}