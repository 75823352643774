import { Menu } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
// import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        {/* <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}> */}
        <Menu.ItemGroup key="product" title="租户">
            <Menu.Item key="/operator/tenant/index" icon={<UnorderedListOutlined />}>
                <Link to="/operator/tenant/index">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>租户列表</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="application" title="应用和服务">
            <Menu.Item key="/operator/application/index" icon={<UnorderedListOutlined />}  >
                <Link to="/operator/application/index">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>应用和服务列表</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key="user" title="运维权限">
            <Menu.Item key="/operator/user/index" icon={<UnorderedListOutlined />}>
                <Link to="/operator/user/index">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>运维人员</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/operator/role/index" icon={<UnorderedListOutlined />}>
                <Link to="/operator/role/index">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>角色</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}



export default SideMenu