import { CheckCircleOutlined, ExclamationCircleOutlined, PauseCircleOutlined, StopOutlined } from "@ant-design/icons";

export default function orderStatus(type: Number) {
    switch (type) {
        case 0: return <span style={{ color: "orange" }}><PauseCircleOutlined /> 待支付</span>
        case 10: return <span style={{ color: "orange" }}><ExclamationCircleOutlined /> 已支付</span>
        case 20: return <span style={{ color: "green" }}><CheckCircleOutlined /> 已发货</span>
        case 30: return <span style={{ color: "#aaa" }}><CheckCircleOutlined /> 已完成</span>
        case 40: return <span style={{ color: "orange" }}><CheckCircleOutlined /> 部分退款</span>
        case 50: return <span style={{ color: "#aaa" }}><CheckCircleOutlined /> 全额退款</span>
        case 1: return <span style={{ color: "#aaa" }}><StopOutlined /> 已取消</span>
        case 2: return <span style={{ color: "#aaa" }}><StopOutlined /> 已超时</span>
        case 3: return <span style={{ color: "#aaa" }}><StopOutlined /> 已撤销</span>

        default:
            break;
    }
}