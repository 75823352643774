import {Menu} from 'antd';
import {UnorderedListOutlined} from '@ant-design/icons';
import {useLocation, Link} from "react-router-dom";

// import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{width: 256}} defaultSelectedKeys={[pathName, fullPathName]} mode="inline">
        {/* <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}> */}
        <Menu.ItemGroup key="product" title="商品">
            <Menu.Item key="/mall/product/index" icon={<UnorderedListOutlined/>}>
                <Link to="/mall/product/index">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span>商品列表</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/mall/product/category" icon={<UnorderedListOutlined/>}>
                <Link to="/mall/product/category">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span>商品分类</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key="order" title="售后">
            <Menu.Item key="/mall/order" icon={<UnorderedListOutlined/>}>
                <Link to="/mall/order">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span>订单列表</span>
                    </div>
                </Link>
            </Menu.Item>

        </Menu.ItemGroup>

        <Menu.ItemGroup key="setting" title="设置">
            <Menu.Item key="/mall/setting" icon={<UnorderedListOutlined/>}>
                <Link to="/mall/setting/realeaseChannel">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <span>物流管理</span>
                    </div>
                </Link>
            </Menu.Item>

        </Menu.ItemGroup>
        {/* </RequireRolesOr> */}
    </Menu>
}


export default SideMenu