import {Button, Flex, Form, Popconfirm, Space, Tooltip} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined} from "@ant-design/icons";
import React from "react";
import {useWatch} from "antd/lib/form/Form";

export default function OrderedMedia({index, form, listPathName, children}: any) {
    const list = useWatch(listPathName, form)

    const exchangeIndex = (index1: any, index2: any) => {
        const node1 = list[index1];
        list[index1] = list[index2];
        list[index2] = node1;
        form.setFieldValue(listPathName, list);
    }

    const remove = () => {
        list.splice(index, 1)
        form.setFieldValue(listPathName, list);
    }

    return <>
        <Form.Item label=' ' labelCol={{span: 1}} wrapperCol={{span: 22}}>
            <Flex justify='space-between'>
                <Button type='primary' shape='circle' size='small' style={{marginLeft: '10px'}}>
                    {index + 1}
                </Button>
                <Space>
                    <Tooltip title='上移'>
                        <Button icon={<ArrowUpOutlined/>} shape='circle' type='primary' size='small'
                                disabled={index === 0}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    exchangeIndex(index, index - 1);
                                }}/>
                    </Tooltip>
                    <Tooltip title='下移'>
                        <Button icon={<ArrowDownOutlined/>} shape='circle' type='primary' size='small'
                                disabled={index === list?.length - 1}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    exchangeIndex(index, index + 1);
                                }}/>
                    </Tooltip>
                    <Tooltip title='删除'>
                        <Popconfirm title='确认删除吗？' okText='是' cancelText='否' onConfirm={remove}>
                            <Button shape='circle' danger icon={<DeleteOutlined/>} size='small'
                                    onClick={(e: any) => e.stopPropagation()}/>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            </Flex>
        </Form.Item>
        {children}
    </>
}