export default function ContentCateList({cateList}: any) {
    return (
        cateList ?
            <>
                {cateList && cateList.map((group: any) => (
                    <div key={group.groupId} style={{textAlign: 'left'}}>
                        {group.groupName}：{group.cateNames.join('-')}
                    </div>))}
            </>
            : <div style={{textAlign: 'left'}}>无</div>
    )
}