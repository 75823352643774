import {useEffect, useRef} from "react";
import MindElixir from "mind-elixir";

export default function PreviewMindMap({data, mediaName}: any) {
    const mindRef = useRef<any>(null);
    const divId = `preview-mind-map-${mediaName}`;
    const initData = {
        root: true,
        topic: mediaName,
        dangerouslySetInnerHTML: `<p>${mediaName}</p>`,
        children: []
    }

    useEffect(() => {
        const mind: any = new MindElixir({
            el: `#${divId}`,
            locale: 'zh_CN',
            editable: false,
            direction: MindElixir.RIGHT,
            before: {
                beginEdit(el) {
                    return false;
                },
            }
        })
        mindRef.current = mind;
        mind.scale(0.8)
        mind.init({nodeData: initData})
    }, []);

    useEffect(() => {
        const mind = mindRef.current;
        mind.refresh(data ? {nodeData: JSON.parse(JSON.stringify(data))} : {nodeData: initData})
    }, [data]);

    return <div id={divId} style={{height: '400px', width: '800px', margin: 'auto', marginBottom: '12px'}}/>
}