import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "dayjs/locale/zh-cn";
import Layout from "./Layout";
import SignIn from "./SignIn";
import CoinCodeTable from "./module.finance/CoinCodeTable";
import Finance from "./module.finance";
import CoinPool from "./module.finance/CoinPool";
import DistributedAccountFlow from "./module.finance/DistributedAccountFlow";
import Mall from "./module.mall";
import Order from "./module.mall/Order";
import Product from "./module.mall/product/Product";
import ProductCategory from "./module.mall/product/ProductCategory";
import TenantList from "./module.operator/TenantList";
import Operator from "./module.operator";
import UserList from "./module.operator/UserList";
import ReleaseChannel from "./module.mall/setting/releaseChannel/releaseChannel";
import Tool from "./module.tool";
import Signature from "./module.tool/jose/signature";
import ApplicationList from "./module.operator/application/List";
import KeyApplicationList from "./module.keys/ApplicationList";
import RoleList from "./module.operator/role/List";
import Key from "./module.keys";
import KeyList from "./module.keys/KeyList";
import Knowledge from "./module.knowledge";
import System from "./module.knowledge/keep/System";
import Category from "./module.knowledge/keep/Category";
import Field from "./module.knowledge/keep/Field";
import FieldTemp from "./module.knowledge/keep/FieldTemp";
import Contents from "./module.knowledge/content/Contents";
import CustomerService from "./module.customer_service";
import Chat from "./module.customer_service/Chat";
import TestClient from "./module.customer_service/TestClient";
import ProfileForm from "./module.customer_service/settings/ProfileForm";
import ThemeForm from "./module.customer_service/settings/ThemeForm";
import CallbacksForm from "./module.customer_service/settings/CallbacksForm";

const router = createBrowserRouter([
  {
    path: "/sign_in",
    element: <SignIn />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/finance",
        element: <Finance />,
        children: [
          {
            path: "/finance/coin/code-table",
            element: <CoinCodeTable />,
          },
          {
            path: "/finance/coin/pool",
            element: <CoinPool />,
          },
          {
            path: "/finance/coin/distributed/finance/account-flow",
            element: <DistributedAccountFlow />,
          },
        ],
      },
      {
        path: "/mall",
        element: <Mall />,
        children: [
          {
            path: "/mall/order",
            element: <Order />,
          },
          {
            path: "/mall/setting/realeaseChannel",
            element: <ReleaseChannel />,
          },
          {
            path: "/mall/product/index",
            element: <Product />,
          },
          {
            path: "/mall/product/category",
            element: <ProductCategory />,
          },
        ],
      },
      {
        path: "/operator",
        element: <Operator />,
        children: [
          {
            path: "/operator/tenant/index",
            element: <TenantList />,
          },
          {
            path: "/operator/user/index",
            element: <UserList />,
          },
          {
            path: "/operator/application/index",
            element: <ApplicationList />,
          },
          {
            path: "/operator/role/index",
            element: <RoleList />,
          },
        ],
      },
      {
        path: "/key",
        element: <Key />,
        children: [
          {
            path: "/key/applications",
            element: <KeyApplicationList />,
          },
          {
            path: "/key/keys",
            element: <KeyList />,
          },
          {
            path: "/key/keys/:application_key",
            element: <KeyList />,
          },
        ],
      },
      {
        path: "/knowledge",
        element: <Knowledge />,
        children: [
          {
            path: "/knowledge/keep/system",
            element: <System />,
          },
          {
            path: "/knowledge/keep/category",
            element: <Category />,
          },
          {
            path: "/knowledge/keep/field",
            element: <Field />,
          },
          {
            path: "/knowledge/keep/template",
            element: <FieldTemp />,
          },
          {
            path: "/knowledge/content/:code",
            element: <Contents />,
          },
          {
            path: "/knowledge/content",
            element: <Contents />,
          },
        ],
      },
      {
        path: "/operator",
        element: <Operator />,
        children: [
          {
            path: "/operator/tenant/index",
            element: <TenantList />,
          },
          {
            path: "/operator/user/index",
            element: <UserList />,
          },
        ],
      },
      {
        path: "/tool",
        element: <Tool />,
        children: [
          {
            path: "/tool/jose/signature",
            element: <Signature />,
          },
        ],
      },
      {
        path: "/customer-service",
        element: <CustomerService />,
        children: [
          {
            path: "/customer-service/chat",
            element: <Chat />,
          },
          {
            path: "/customer-service/test-client",
            element: <TestClient />,
          },
          {
            path: "/customer-service/setting/profile",
            element: <ProfileForm />,
          },
          {
            path: "/customer-service/setting/theme",
            element: <ThemeForm />,
          },
          {
            path: "/customer-service/setting/callbacks",
            element: <CallbacksForm />,
          },
        ],
      }
    ],
  },
  {
    path: "*",
    element: <SignIn />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
