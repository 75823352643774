import {Button, Collapse, Divider, Form, Image, message, Tabs, Timeline} from "antd";
import {CloseCircleOutlined, CopyTwoTone, PayCircleOutlined, TransactionOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import './OrderDetail.css';
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../components/global";
import refundType from "./refund_type";
import ExpressInfo from "./ExpressInfo";

export default function OrderDetail({order}: any) {
    const currentTenant = useSelector(selectCurrentTenant)
    const jsonRequest = useSelector(selectJsonRequest)

    const [form] = Form.useForm();
    const orderDetails = order.extendInfo || [];
    const address = order.address || {};
    const shipping = order.shippingManifest || {};
    const [refundRecords, setRefundRecords] = useState([]);

    useEffect(() => {
        getRefundRecord();
    }, []);

    const getRefundRecord = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/refund/",
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {
                orderId: order.id,
                transactionId: order.transactionId,
            }
        })
            .then(resp => resp.json())
            .then(({data}: any) => {
                setRefundRecords(data.data)
            })
    }

    const handleCopy = (content: any) => {
        navigator.clipboard.writeText(content)
            .then(() => {
                message.success({content: '已复制到剪切板中', key: "exporting", duration: 3})
            })
            .catch(() => {
                message.error({content: '写入剪切板错误', key: "exporting", duration: 3})
                navigator.clipboard.writeText("")
            })
    }

    const OrderDetails = () => (
        <div style={{padding: '10px'}}>
            {orderDetails.map((detail: any) => (
                <div className="sku-item" key={detail.id}>
                    <h3> {detail.productTitle} <span
                        style={{color: "#aaa"}}>x {detail.quantity}</span></h3>
                    <Image className="img" src={detail.productSku?.images?.[0].objName}
                           width={80}/>
                </div>
            ))}
        </div>
    )

    const ShippingInfo = () => (
        <div style={{padding: '10px'}}>
            <Collapse defaultActiveKey={[]} items={[
                {
                    key: 'address',
                    label: '地址',
                    children: <div>
                        <Form.Item label="姓名">
                            {address.fullname}
                        </Form.Item>
                        <Form.Item label="电话">
                            {address.phone}
                        </Form.Item>
                        <Form.Item label="省">
                            {address.province}
                        </Form.Item>
                        <Form.Item label="市">
                            {address.city}
                        </Form.Item>
                        <Form.Item label="区">
                            {address.district}
                        </Form.Item>
                        <Form.Item label="街道">
                            {address.street}
                        </Form.Item>
                        <Form.Item label="地址">
                            {address.address}
                        </Form.Item>
                    </div>
                },
                {
                    key: 'shipping',
                    label: '发货单',
                    children: <div>
                        <Form.Item label="快递单号">{shipping.expressNo}</Form.Item>
                        <Form.Item label="快递状态">{shipping.logisticsStatus}</Form.Item>
                        <Form.Item label="快递公司">{shipping.releaseChannel?.channelName}</Form.Item>
                        <Form.Item label="收货地址">{shipping.consigneeAddress}</Form.Item>
                        <Form.Item label="收货人">{shipping.consigneeRealname}</Form.Item>
                        <Form.Item label="收货人电话">{shipping.consigneeTelephone}</Form.Item>
                        <Form.Item label="发货时间">{shipping.logisticsCreateAt}</Form.Item>
                    </div>
                },
                {
                    key: 'express',
                    label: '物流信息',
                    children: <ExpressInfo id={order.shippingManifest?.id}/>
                }
            ]}/>
        </div>
    )

    const BusinessRecords = () => {
        const payRecord = (
            <div>
                <p>{dayjs.tz(order.paidAt).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</p>
                <p>{order.tradeChannelName}支付 {order.totalAmount}</p>
                {order.buyerMessage && <p>备注：{order.buyerMessage}</p>}
            </div>
        )
        const refunds = refundRecords.map((record: any) => (
            {
                dot: <TransactionOutlined style={{color: '#f44336'}}/>,
                children: (<div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        {record.status === 0 &&
                            <span style={{color: '#f44336'}}><CloseCircleOutlined/>未退款</span>}
                        <span>
                            {dayjs.tz(record.refundDate).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                        {refundType(record.refundType)}
                    </div>
                    <p>{record.channel}退回 {record.refundAmount}</p>
                    <p>备注：{record.remark}</p>
                </div>)
            }
        ))
        return (
            <div style={{padding: '10px'}}>
                <Timeline items={[
                    ...refunds,
                    {dot: <PayCircleOutlined/>, children: payRecord,}
                ]}/>
            </div>
        )
    }

    const tabItems = [
        {
            key: 'product',
            label: '商品信息',
            children: <OrderDetails/>
        },
        {
            key: 'shipping',
            label: '发货信息',
            children: <ShippingInfo/>,
        },
        {
            key: 'business',
            label: '交易记录',
            children: <BusinessRecords/>,
        }
    ];

    return (
        <Form form={form} style={{display: 'flex'}} labelCol={{span: 6}} wrapperCol={{span: 16}}
              className="order-detail-form">
            <div style={{flex: 1}}>
                <Divider>订单信息</Divider>
                <Form.Item label="订单编号">
                    {order.transactionId}
                    <Button type="link" size="small" icon={<CopyTwoTone/>}
                            onClick={() => handleCopy(order.transactionId)}>点击复制</Button>
                </Form.Item>
                <Form.Item label="实付款">{order.totalAmount}</Form.Item>
                <Form.Item label="买家备注">{order.buyerMessage}</Form.Item>
                <Form.Item label="客服备注">{order.remark}</Form.Item>
                <Form.Item label="支付货币">{order.currencyName}</Form.Item>
                <Form.Item label="支付渠道">{order.tradeChannelName}</Form.Item>
                <Form.Item
                    label="下单时间">{dayjs.tz(order.orderedAt).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</Form.Item>
                {order.paidAt && <Form.Item
                    label="支付时间">{dayjs.tz(order.paidAt).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</Form.Item>}
            </div>
            <Divider type="vertical" style={{height: 'auto'}}/>
            <div style={{flex: 1}}>
                <Tabs tabPosition="right" items={tabItems}/>
            </div>
        </Form>
    )
}