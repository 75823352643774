import {useState, useEffect} from "react";
import {Flex, Upload} from "antd";

import {useSelector} from "react-redux";
import Sha256Encode from "../components/sha256";
import {selectJsonRequest, selectCurrentTenant} from "./global";

export default function UploadComponent({value, onChange, path, children, ...props}: any) {
    const [OSSData, setOSSData]: any = useState();

    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)

    const init = async () => {
        const result = await jsonRequest.post("/api/request", {
            path: `/api/oss/object/policy`,
            method: "get",
            application_key: "zy:services::attachment",
            tenant_meta: currentTenant,
            data: {file_size: 4096000}
        })
            .then((resp: any) => resp.json())
        setOSSData(result.data?.data)
    };

    useEffect(() => {
        init();
    }, [currentTenant]);

    const handleChange = ({fileList}: any) => {
        // console.log('Aliyun OSS:', fileList);
        onChange?.([...fileList]);
    };

    const onRemove = (file: any) => {
        const files = (value || []).filter((v: any) => v.url !== file.url);

        // if (onChange) {
        //     onChange(files);
        // }
    };

    const getExtraData = (file: any) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.accessid,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
    });

    const beforeUpload = async (file: any) => {
        if (!OSSData) return false;

        const expire = Number(OSSData.expire) * 1000;

        if (expire < Date.now()) {
            await init();
        }

        const file_content = await file.arrayBuffer()
        const file_name = await Sha256Encode(file_content)

        file.url = [path, file_name].join("/")
        console.log(file.url)
        return file;
    };

    const uploadProps = {
        name: 'file',
        fileList: value,
        action: OSSData?.host,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        beforeUpload,
    };
    
    return (
        <Upload {...props} {...uploadProps} >
            {children}
        </Upload>
    );
};
