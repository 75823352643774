import {Select} from "antd";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";

export default function RefSelector({content, ...props}: any) {
    const {isMultiple, inputPrompt} = content;
    const refOptions = content.contentList?.map(({id, name}: any) => ({label: name, value: id}));

    return <Select
        {...props}
        placeholder={inputPrompt}
        mode={isMultiple ? "multiple" : undefined}
        options={refOptions}
        filterOption={(input, option: any) => (option?.label ?? '').includes(input)}
    />
}