import { message, Input, Button, Form } from "antd"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { selectJsonRequest, selectCurrentTenant } from "../../components/global"
import JsonRequest from "../../components/jsonRequest"

export default function CallbacksForm(params: any) {

    const jsonRequest: JsonRequest = useSelector(selectJsonRequest)
    const currentTenant: string = useSelector(selectCurrentTenant)

    const [form] = Form.useForm()

    useEffect(() => {
        handleLoadData(currentTenant)
    }, [currentTenant])

    const handleLoadData = async (currentTenant: string) => {
        if (!currentTenant) return;

        var response = await jsonRequest.post(`/api/request/api/tenant/detail`, { method: "get", application_key: "zy:services::customer-service", tenant_meta: currentTenant, data: {} })

        form.setFieldsValue(response.body.data.callbacks);
    }

    const handleSubmit = async (values: any) => {
        var resp = await jsonRequest.post(`/api/request/api/tenant/profile`, { method: "put", application_key: "zy:services::customer-service", tenant_meta: currentTenant, data: { callbacks: values } })
        if (resp.ok) {
            form.setFieldsValue(resp.body.data.callbacks);
        } else {
            message.error("保存失败")
        }

    }

    return <div className="page coin-code-table">
        <h1>回调设置</h1>

        <div className="content" style={{ width: 600 }}>
            <Form form={form} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit}
                autoComplete="off">
                <Form.Item label="新消息推送给客服" name="new_message_to_instant_url" help="暂时无用">
                    <Input />
                </Form.Item>
                <Form.Item label="新消息推送给用户" name="new_message_to_customer_url" help="只有客服场景才会使用，当用户不在线时，会推送信息，参数：https://doc.zhi-yuan.net/web/#/610960622/285084874">
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form.Item>
            </Form>
        </div>

    </div>

}