
import { Table, Modal, Form, Input, message, Select, Button, InputNumber, Switch } from "antd";
import { useState, useEffect, useRef } from "react";
import { connect, useSelector } from 'react-redux';
import { EditOutlined, PlusSquareTwoTone } from "@ant-design/icons"
// import { JsonEditor as Editor } from 'jsoneditor-react';
import Ajv from 'ajv';
import ace from 'brace';
import { selectCurrentTenant, selectJsonRequest } from "../components/global";

const ajv = new Ajv({ allErrors: true, verbose: true });
function CoinCodeTable(props) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    let [form] = Form.useForm()
    // json编辑器的关联
    const jsonEditorRef = useRef()

    const jsonClient = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)

    useEffect(() => {
        if (!!currentTenant)
            handleLoadData()

        return () => {
        }
    }, [currentTenant])

    const handleLoadData = () => {
        jsonClient.post("/api/request/api/coin/code_table", { method: "get", application_key: "zy:services::finance", tenant_meta: currentTenant, data: form.getFieldsValue() })
            .then(resp => resp.json())
            .then(({ data }) => {
                setLoading(false)
                setData(data)
            })
    }

    const handleSave = (initialValues) => {
        form.resetFields()
        form.setFieldsValue(initialValues || {})
        Modal.confirm({
            width: 1200,
            title: '添加事件',
            content: <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ flex: 1 }}>
                    <Form.Item label="事件key" name="event_key" rules={[{ required: true, message: '必填' }]}>
                        <Input disabled={!!initialValues} />
                    </Form.Item>
                    <Form.Item label="名称" name="name" rules={[{ required: true, message: '必填' }]}>
                        <Input placeholder="" disabled={!!data?.id} />
                    </Form.Item>
                    <Form.Item label="简介" name="summary">
                        <Input placeholder="介绍如何得到该积分" />
                    </Form.Item>
                    <Form.Item label="货币" name="currency_meta" rules={[{ required: true, message: '必填' }]} initialValue="zy:application:acupoint:currency::coin">
                        <Select>
                            <Select.Option value="zy:application:acupoint:currency::coin">积分</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="可获得数量" name="amount" rules={[{ required: true, message: '必填' }]} initialValue={1}>
                        <InputNumber min={1} step={1} />
                    </Form.Item>
                    <Form.Item label="需积累次数" name="trigger_times" rules={[{ required: true, message: '必填' }]} initialValue={1} help="需积累多少次才可以触发并获得积分">
                        <InputNumber min={1} step={1} />
                    </Form.Item>
                    <Form.Item label="最大可完成次数" name="max_completion_times" initialValue={null} help="积分项在整个账号下可完成次数">
                        <InputNumber min={1} step={1} />
                    </Form.Item>
                    <Form.Item label="循环周期" name="loop_duration_num" rules={[{ required: true, message: '必填' }]} initialValue={1} help="重置的周期计算由不同的单位的0开始。比如小时是0分、天是0点、周是周一0时、月是月初0时">
                        <InputNumber min={1} step={1} addonAfter={
                            <Form.Item name="loop_duration_unit" noStyle initialValue="day">
                                <Select style={{ width: 70 }}>
                                    <Select.Option value="hour">小时</Select.Option>
                                    <Select.Option value="day">天</Select.Option>
                                    <Select.Option value="week">周</Select.Option>
                                    <Select.Option value="month">月</Select.Option>
                                </Select>
                            </Form.Item>
                        } />
                    </Form.Item>
                    <Form.Item label="排序" name="index" rules={[{ required: true, message: '必填' }]} initialValue={0}>
                        <InputNumber min={0} step={1} />
                    </Form.Item>
                </div>

                <div style={{ flex: 1 }}>
                    <Form.Item label="附加参数" name="data" initialValue={{}}>
                        {/* <Editor ref={jsonEditorRef} ace={ace} mode="code" ajv={ajv} language="zh-CN" /> */}
                    </Form.Item>

                    <Form.Item label="账户流水摘要" name="account_flow_summary" help="用户查看积分历史记录时候展示，默认是码表名称">
                        <Input placeholder="" disabled={!!data?.id} />
                    </Form.Item>

                    <Form.Item label="是否可用" name="available" valuePropName="checked">
                        <Switch></Switch>
                    </Form.Item>
                </div>
            </Form>,
            onOk: (close) => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        jsonClient.post(`/api/request/api/coin/code_table/${values.event_key}`, { application_key: "zy:services::finance", tenant_meta: currentTenant, data: values })
                            .then(resp => resp.json())
                            .then(() => {
                                form.resetFields()
                                close()
                                message.success("保存成功")
                                handleLoadData()
                            })
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
                return false
            }
        });
    }

    return <div className="page coin-code-table">
        <h1>积分码表</h1>

        <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
            <Button icon={<PlusSquareTwoTone />} type="link" onClick={() => handleSave()}>
                新增事件码表
            </Button>
        </div>
        <div>
            <Table dataSource={data} loading={loading} pagination={false}>
                <Table.Column align="left" title="事件key" dataIndex="event_key" key="event_key" />
                <Table.Column align="left" title="名称" dataIndex="name" key="name" />
                <Table.Column align="left" title="获得描述" dataIndex="summary" key="summary" />
                <Table.Column align="left" title="账户流水摘要" dataIndex="account_flow_summary" key="account_flow_summary" render={(text, i) => text || i.name} />
                <Table.Column align="center" title="货币" dataIndex="currency_meta" key="currency_meta" />
                <Table.Column align="right" title="可获得数量" dataIndex="amount" key="amount" />
                <Table.Column align="right" title="需积累次数" dataIndex="trigger_times" key="trigger_times" />
                <Table.Column align="right" title="最大可完成次数" dataIndex="max_completion_times" key="max_completion_times" render={v => v || "♾️"} />
                <Table.Column align="center" title="循环周期" dataIndex="loop_duration_num" key="loop_duration_num" render={(text, item) => `${text} ${item.loop_duration_unit}`} />
                <Table.Column align="center" title="可用" dataIndex="available" key="available" render={(v) => <Switch checked={v} disabled checkedChildren="启用"></Switch>} />
                <Table.Column align="center" title="操作" render={(text, item) => {
                    return <>
                        <Button type="link" icon={<EditOutlined />} onClick={() => handleSave(item)}>编辑</Button>
                    </>
                }} />
            </Table>
        </div>
    </div>
}


const mapStateToProps = ({ global }) => {
    return {
        jsonRequest: global.jsonRequest
    }
}
const mapDispatchToProps = () => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CoinCodeTable);