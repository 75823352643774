import {Form, Input, message} from "antd";
import PictureInput from "./PictureInput";
import FileInput from "./FileInput";
import React from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import OssPath from "../../../components/oss_path";
import OrderedMedia from "../../content/components/OrderedMedia";

export default function VideoInput({form, prefix, fullPrefix, field, index, listPathName}: any) {
    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)
    const {isRequired} = field;

    const onFileChange = (file: any) => {
        if (!file) return;
        // 设置音频名称
        const videoName = form.getFieldValue([...fullPrefix, 'videoName']);
        if (!videoName) {
            const name = file.name.substring(0, file.name.lastIndexOf("."));
            form.setFieldValue([...fullPrefix, 'videoName'], name)
        }
        // 设置视频时长
        setVideoDuration(file.url);
        // 设置m3u8地址
        setHlsUrl(file.originFileObj?.url);
    }

    const setHlsUrl = async (url: string) => {
        const {data} = await jsonRequest.post("/api/request", {
            path: `/v1/aliyun/video/hls`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {objectKey: url}
        })
            .then((resp: any) => resp.json())
        const hlsUrl = await OssPath(jsonRequest, currentTenant, 'OssM3u8Path') + btoa(data) + '.m3u8';
        form.setFieldValue([...fullPrefix, 'hlsUrl'], hlsUrl)
    }

    const setVideoDuration = (url: any) => {
        const audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", (e) => {
            const time = Math.floor(audioElement.duration) || 0;
            form.setFieldValue([...fullPrefix, 'duration'], time)
        })
        audioElement.addEventListener("error", (e) => {
            message.error('获取音频时长失败');
        })
    }

    const InputBody = () => <>
        <Form.Item label={`视频标题`} name={[...prefix, "videoName"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入视频标题"/>
        </Form.Item>
        <Form.Item label={`视频描述`} name={[...prefix, "description"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入视频描述"/>
        </Form.Item>
        <PictureInput form={form} prefix={prefix} fullPrefix={fullPrefix}
                      field={field} labelPre="视频封面"/>
        <FileInput form={form} labelPre="视频" prefix={prefix} fullPrefix={fullPrefix}
                   type="video" accept='video/*' field={field} namePre="video" onChange={onFileChange}/>
        <Form.Item name={[...prefix, 'hlsUrl']} hidden initialValue={''}><Input/></Form.Item>
        <Form.Item label={`视频时长（秒）`} name={[...prefix, "duration"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入视频时长"/>
        </Form.Item>
        <FileInput form={form} labelPre="字幕" prefix={prefix} fullPrefix={fullPrefix}
                   type="other" field={field} namePre="subtitle"/>
    </>

    return listPathName ? <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <InputBody/>
    </OrderedMedia> : <InputBody/>
}