const OssPath = async (jsonRequest: any, currentTenant: any, name: string = 'OssPath') => {

    const storage = localStorage.getItem(`knowledge-oss-path-${name}`)
    if (storage) return storage;

    const {data} = await jsonRequest.post("/api/request", {
        path: `/v1/parameters/${name}`,
        method: "get",
        application_key: "zy:application::knowledge-foundation",
        tenant_meta: currentTenant,
        data: {}
    })
        .then((resp: any) => resp.json())
    localStorage.setItem(`knowledge-oss-path-${name}`, data.value)
    return data.value;
}

export default OssPath;