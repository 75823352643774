import React, {useEffect, useState} from "react";
import {Col, Form, Input, Row, Select, Radio, InputNumber} from "antd";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import FieldMediaTemp from "./field_media_temp";
import {useWatch} from "antd/lib/form/Form";

export default function FieldTempForm({form, detail, tenants, mediaTypes, fieldTypes}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [fields, setFields] = useState([])
    const [field, setField]: any = useState({})
    const mediaType: any = useWatch('mediaType', form)

    useEffect(() => {
        form.resetFields();
        if (detail.systemCode)
            form.setFieldsValue({systemCode: detail.systemCode, fieldType: detail.fieldType})
        getFields(detail.fieldId);
    }, []);

    const getFields = (fieldId: any) => {
        const {systemCode, fieldType} = form.getFieldsValue();
        const params = {
            systemCode: systemCode || tenants[0].systemCode,
            fieldType: fieldType || fieldTypes[0].enumKey,
        }
        form.setFieldValue('fieldId', null)
        jsonRequest.post("/api/request", {
            path: "/v1/system-fields",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: params,
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setFields(data)
                form.setFieldsValue({
                    ...params,
                    fieldId: fieldId || data[0]?.id,
                })
                if (data[0])
                    selectField(fieldId || data[0]?.id)
                else {
                    const {systemCode, fieldType, fieldId} = form.getFieldsValue()
                    form.resetFields();
                    // setMediaType(null);
                    form.setFieldsValue({systemCode, fieldType, fieldId})
                    setField({});
                }
            })
    }

    const selectField = (id: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/system-fields/temp/${id}`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                if (data.id) {
                    form.setFieldsValue(data);
                } else {
                    const {systemCode, fieldType, fieldId} = form.getFieldsValue()
                    form.resetFields();
                    // setMediaType(null);
                    form.setFieldsValue({systemCode, fieldType, fieldId})
                }
                setField(data)
            })
    }

    const TypeFields = () => {
        const type = form.getFieldValue('fieldType')
        const InputPrompt = (
            <Form.Item label="输入提示" name={["extData", "inputPrompt"]}
                       rules={[{required: true, message: '不能为空'}]}>
                <Input/>
            </Form.Item>
        )
        switch (type) {
            case "content":
            case "content_cate_info":
                return <>
                    <Form.Item label="媒体类型" name="mediaType" rules={[{required: true, message: '不能为空'}]}>
                        <Select allowClear
                                options={mediaTypes.map((e: any) => ({value: e.enumKey, label: e.enumName}))}/>
                    </Form.Item>
                    <Form.Item noStyle name='mediaExtra' dependencies={["mediaType"]}>
                        <FieldMediaTemp mediaType={mediaType} tenants={tenants}/>
                    </Form.Item>
                </>
            case "content_ref":
                return <>
                    <Form.Item label="引用知识体系" name={["extData", "systemCode"]}
                               rules={[{required: true, message: '不能为空'}]}>
                        <Select allowClear
                                options={tenants.map((e: any) => ({value: e.systemCode, label: e.systemName}))}/>
                    </Form.Item>
                    {InputPrompt}
                    <Form.Item label="是否多选" name={["extData", "isMultiple"]}
                               rules={[{required: true, message: '不能为空'}]}>
                        <Radio.Group options={[{label: '是', value: true}, {label: '否', value: false}]}/>
                    </Form.Item>
                </>
            case "content_cate":
                return <>
                    {InputPrompt}
                    <Form.Item label="是否多选" name={["extData", "isMultiple"]}
                               rules={[{required: true, message: '不能为空'}]}>
                        <Radio.Group options={[{label: '是', value: true}, {label: '否', value: false}]}/>
                    </Form.Item>
                </>
        }
        return <>
            {InputPrompt}
        </>
    }

    return (
        <Form form={form} size="small" name="basic"
              labelCol={{span: 4}} wrapperCol={{span: 16}}>
            <Form.Item label="ID" name="id" style={{display: "none"}}>
                <Input disabled/>
            </Form.Item>
            <Form.Item label="字段ID" name="fieldId" style={{display: "none"}}>
                <Input disabled/>
            </Form.Item>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label="知识体系" name="systemCode"
                               labelCol={{span: 8}} wrapperCol={{span: 8}}>
                        <Select onChange={() => getFields(null)} allowClear disabled={detail?.fieldId}
                                options={tenants.map((e: any) => ({value: e.systemCode, label: e.systemName}))}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="字段类型" name="fieldType"
                               labelCol={{span: 8}} wrapperCol={{span: 8}}>
                        <Select onChange={() => getFields(null)} allowClear disabled={detail?.fieldId}
                                options={fieldTypes.map((e: any) => ({value: e.enumKey, label: e.enumName}))}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label="配置字段" name="fieldId" rules={[{required: true, message: '不能为空'}]}
                       hidden={detail.quick}>
                <Radio.Group buttonStyle="solid" optionType="button" size="middle"
                             onChange={(e) => selectField(e.target.id)}
                             options={fields.map((e: any) => ({value: e.id, label: e.fieldName, ...e}))}/>
            </Form.Item>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label="上级字段名称" labelCol={{span: 8}} wrapperCol={{span: 8}}>
                        {field.parentName || '无'}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="字段名称" labelCol={{span: 8}} wrapperCol={{span: 8}}>
                        {field.fieldName}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label="字段Key" labelCol={{span: 8}} wrapperCol={{span: 10}}>
                        {field.fieldKey}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="字段类型" labelCol={{span: 8}} wrapperCol={{span: 10}}>
                        {fieldTypes.find((e: any) => e.enumKey === field.fieldType)?.enumName}
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label="所属分组">
                {field.groupName || detail.groupName}
            </Form.Item>
            <Form.Item label="排序号" name="index" rules={[{required: true, message: '不能为空'}]}>
                <InputNumber/>
            </Form.Item>
            <Form.Item noStyle name="typeFiedls" dependencies={['fieldId', 'mediaType']}>
                <TypeFields/>
            </Form.Item>
            <Form.Item label="是否必填" name="isRequired" rules={[{required: true, message: '不能为空'}]}>
                <Radio.Group options={[{label: '是', value: true}, {label: '否', value: false}]}/>
            </Form.Item>
            <Form.Item label="是否可用" name="state" rules={[{required: true, message: '不能为空'}]}>
                <Radio.Group options={[{label: '是', value: true}, {label: '否', value: false}]}/>
            </Form.Item>
        </Form>
    )
}