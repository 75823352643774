import { Menu } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
// import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        {/* <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}> */}
        <Menu.ItemGroup key="coin" title="积分">
            <Menu.Item key="/finance/coin/code-table" icon={<UnorderedListOutlined />}>
                <Link to="/finance/coin/code-table">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>积分配置表</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/finance/coin/pool" icon={<UnorderedListOutlined />}>
                <Link to="/finance/coin/pool">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>积分池</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        {/* </RequireRolesOr> */}

        {/* <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}> */}
        <Menu.ItemGroup key="finance" title="分布式财务（积分）系统">
            <Menu.Item key="/finance/coin/distributed/finance/account-flow" icon={<UnorderedListOutlined />}>
                <Link to="/finance/coin/distributed/finance/account-flow">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>账户流水</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
        {/* </RequireRolesOr> */}
    </Menu >
}



export default SideMenu