import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {selectJsonRequest} from "../components/global"
import {Button, Form, Input, List, Modal, Tooltip, message} from "antd"
import {KeyOutlined, PlusCircleTwoTone} from "@ant-design/icons"
import GlobalWrap from "../components/globalWrap"

export default function TenantList() {
    const [data, setData]: any = useState({})
    const [loading, setLoading] = useState(true)
    const jsonRequest = useSelector(selectJsonRequest)
    const [tenantForm] = Form.useForm()

    useEffect(() => {
        handleLoad()

        return () => {
        }
    }, [])

    const handleLoad = async () => {
        const responseJson = (await jsonRequest.get("/api/tenant", {})).json()
        setData(await responseJson)
        setLoading(false)
    }

    const handleCreateTenant = () => {
        Modal.confirm({
            width: 500,
            title: '录入租户',
            content: <GlobalWrap>
                <Form form={tenantForm} name="basic" labelCol={{span: 4}} wrapperCol={{span: 16}} autoComplete="off">
                    <Form.Item label="租户名称" name="name" rules={[{required: true, message: '不能为空'}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label="租户元数据" name="meta" rules={[{required: true, message: '不能为空'}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label="租户签名密钥" name="signature_secret"
                               rules={[{required: true, message: '不能为空'}]}
                               help="JSON格式的JOSE私钥用Base64编码">
                        <Input.TextArea/>
                    </Form.Item>

                </Form>
            </GlobalWrap>,
            onOk: async (close) => {
                let values = await tenantForm.validateFields()

                if ((await jsonRequest.post("/api/tenant", values)).ok) {
                    message.success("录入完成")
                    tenantForm.resetFields();
                    handleLoad()
                    close()
                } else {
                    message.error("录入失败")
                }

                return true
            },
        })
    }


    return <div className="page mall-order">
        <h1>租户列表 <Button onClick={() => handleCreateTenant()} type="link" size="small"
                             icon={<PlusCircleTwoTone/>}>录入租户</Button></h1>

        <div className="content">
            <List dataSource={data.data}
                  loading={loading}
                  rowKey={d => d.meta}
                  grid={{gutter: 16, column: 4}}
                  itemLayout="vertical"
                  size="large"
                  renderItem={(item: any) => (
                      <List.Item key={item.id} className="item-order" extra={<div>
                          {item.signature_secret && <Tooltip title="记录了私钥"><KeyOutlined/></Tooltip>}
                      </div>}>
                          <div>{item.name}</div>
                          <div>{item.meta}</div>
                      </List.Item>
                  )}>

            </List>
        </div>
    </div>
}