import {Button, Form, Input, Modal} from "antd";
import GlobalWrap from "../../../components/globalWrap";
import ContentMindMap from "./ContentMindMap/ContentMindMap";
import React from "react";
import PreviewMindMap from "./ContentMindMap/PreviewMindMap";
import {useWatch} from "antd/lib/form/Form";

export default function MindMapInput({form, prefix, fullPrefix, field}: any) {
    const {mediaName} = field;
    const nodeData = useWatch([...fullPrefix, 'nodeData'], form)
    const initNodeData = {
        root: true,
        topic: mediaName,
        dangerouslySetInnerHTML: `<p>${mediaName}</p>`,
        children: []
    }
    let model: any;

    const onCancel = () => {
        model.destroy();
    }

    const onOk = (values: any) => {
        form.setFieldValue([...fullPrefix, 'nodeData'], values)
        model.destroy();
    }

    const handleMindMap = () => {
        const initialValues = form.getFieldValue([...fullPrefix, 'nodeData']);
        model = Modal.confirm({
            width: '90vw',
            title: <span>思维导图编辑</span>,
            footer: null,
            content: <GlobalWrap>
                <ContentMindMap initialValues={initialValues} onCancel={onCancel} onOk={onOk}/>
            </GlobalWrap>
        })
    }

    return <>
        <PreviewMindMap data={nodeData} mediaName={mediaName}/>
        <Form.Item wrapperCol={{offset: 2, span: 20}}><Button size='middle' block onClick={handleMindMap}>编辑</Button></Form.Item>
        <Form.Item name={[...prefix, 'nodeData']} hidden initialValue={initNodeData}><Input/> </Form.Item>
    </>
}