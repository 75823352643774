import {Form, Input, InputNumber, Select} from "antd";
import logisticsTypes from "./logisticsTypes";

const {Option} = Select;

export default function ReleaseChannelForm({form, channel}: any) {
    form.setFieldsValue(channel)

    return (
        <Form form={form} size="small" name="basic" labelCol={{span: 6}} wrapperCol={{span: 16}} autoComplete="off">
            <Form.Item label="物流名称" name="channelName" rules={[{required: true, message: '不能为空'}]}>
                <Input/>
            </Form.Item>
            <Form.Item label="物流类型" name="logisticsType" initialValue={1}
                       rules={[{required: true, message: '不能为空'}]}>
                <Select>
                    {Object.keys(logisticsTypes).map((item) => (
                        <Option key={item}
                                value={parseInt(item)}>{logisticsTypes[item]}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label="代理费" name="agencyFee" initialValue={0} rules={[{required: true, message: '不能为空'}]}>
                <InputNumber/>
            </Form.Item>
        </Form>
    )
}