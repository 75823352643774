import {Tag} from "antd";

export default function refundType(type: Number) {
    switch (type) {
        case 0:
            return <Tag color="warning">退货退款</Tag>
        case 1:
            return <Tag color="warning">没收到货退款</Tag>
        case 2:
            return <Tag color="warning">收到货退款</Tag>
        default:
            break;
    }
}