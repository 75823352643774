import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Form,
  Image,
  message,
  Modal,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import {
  selectCurrentTenant,
  selectJsonRequest,
} from "../../components/global";
import { EditableCell } from "./EditableCell";
import dayjs from "dayjs";
import { ExtendInfoItem, OrderItem } from "../types";
import css from "./TableList.module.css";

type Props = {
  count: number;
  data: OrderItem[];
  pageSize: number;
  pageNumber: number;
  handlePageTo: (page: number, size: number) => void;
};
const TableList: React.ForwardRefRenderFunction<any, Props> = (
  { count, data, pageSize, pageNumber, handlePageTo }: Props,
  ref,
) => {
  const [form] = Form.useForm();
  const [releaseChannels, setReleaseChannels]: any = useState([]);
  const jsonRequest = useSelector(selectJsonRequest);
  const currentTenant = useSelector(selectCurrentTenant);

  useEffect(() => {
    jsonRequest
      .post("/api/request", {
        path: "/v1/releaseChannel/",
        method: "get",
        application_key: "zy:services::mall",
        tenant_meta: currentTenant,
        data: {},
      })
      .then((resp) => resp.json())
      .then(({ data }: any) => {
        setReleaseChannels(data);
      });
  }, []);
  const [editing, setEditing] = useState<string[]>([]);

  const handleEdit = (record: OrderItem, action: "edit" | "cancel") => {
    if (action === "edit") {
      setEditing([record.id, ...editing]);
    } else if (action === "cancel") {
      const index = editing.indexOf(record.id);
      if (index === -1) return;
      const arr = [...editing];
      arr.splice(index, 1);
      setEditing(arr);
    }
  };

  const columns: any = [
    { title: "订单编号", dataIndex: "id", width: 300 },
    { title: "姓名", width: 100, dataIndex: ["address", "fullname"] },
    { title: "电话", dataIndex: ["address", "phone"], width: "120px" },
    {
      title: "地址",
      width: "auto",
      key: "id",
      render: (_: any, record: OrderItem) => {
        return (
          <div
            style={{ whiteSpace: "nowrap" }}
          >{`${record.address.fullAddress || ""} ${record.address.address || ""}`}</div>
        );
      },
    },
    {
      title: "商品名称",
      width: "auto",
      ellipsis: true,
      key: "id",
      render: (_: any, record: OrderItem) => {
        return (
          <div>
            {record.extendInfo?.map((item: ExtendInfoItem, index: number) => {
              return <div key={index}>{item.productTitle}</div>;
            })}
          </div>
        );
      },
    },
    {
      title: "商品图片",
      width: "auto",
      key: "id",
      align: "center",
      render: (_: any, record: OrderItem) => {
        return (
          <div className={css.logoContainer}>
            {record.extendInfo.map((detail: ExtendInfoItem) => (
              <div className={css.logo}>
                <Image
                  src={detail.productSku?.images?.[0].objName}
                  height={22}
                />
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "实付款",
      dataIndex: "paymentAmount",
      width: "auto",
      align: "center",
    },
    {
      title: "支付货币/渠道",
      width: "auto",
      key: "id",
      align: "center",
      render: (_: any, record: OrderItem) => {
        return `${record.currencyName || ""}/${record.tradeChannelName || ""}`;
      },
    },
    {
      title: "备注",
      dataIndex: "buyerMessage",
      width: "auto",
      ellipsis: true,
    },
    // {
    //   title: "状态",
    //   width: "auto",
    //   key: "id",
    //   render: (_: any, record: OrderItem) => {
    //     return orderStatus(record.status);
    //   },
    // },
    {
      title: "物流公司",
      dataIndex: "releaseLogisticsId",
      key: "id",
      editable: true,
      fixed: "right",
      width: 200,
      inputType: "selection",
      render: (_: any, record: OrderItem) => {
        return (
          <EditableCell
            record={record}
            inputType="selection"
            dataIndex="releaseLogisticsId"
            releaseChannels={releaseChannels}
            editing={editing}
            setEditing={setEditing}
            title="物流公司"
          />
        );
      },
    },
    {
      title: "物流单号",
      dataIndex: "expressNo",
      key: "id",
      editable: true,
      fixed: "right",
      width: 200,
      render: (_: any, record: OrderItem) => {
        return (
          <EditableCell
            record={record}
            setEditing={setEditing}
            editing={editing}
            inputType="text"
            dataIndex="expressNo"
            title="物流单号"
          />
        );
      },
    },
    {
      title: "操作",
      width: "80px",
      key: "operation",
      fixed: "right",
      align: "center",
      render: (_: any, record: OrderItem) => {
        return editing.includes(record.id) ? (
          <Popconfirm
            title="确定取消吗?"
            onConfirm={() => handleEdit(record, "cancel")}
          >
            <a>取消</a>
          </Popconfirm>
        ) : (
          <Typography.Link onClick={() => handleEdit(record, "edit")}>
            编辑
          </Typography.Link>
        );
      },
    },
  ];

  const handleSubmit = async (arr: typeof data) => {
    await form.validateFields();
    const values: [string, unknown][] = Object.entries(
      form.getFieldsValue(),
    ).filter((arr: [string, unknown]) => !!arr[1]);

    if (!values.length) {
      message.warning("无有效数据！");
      throw Error("无有效数据！");
    }
    const params = values.reduce((_: any[], key) => {
      const [name, id] = key[0].split(",");
      let current = _.find((item: any) => item.orderId === id);
      if (current) {
        current[name] = key[1];
      } else {
        const item = arr.find((item: OrderItem) => item.id === id);
        if (item) {
          current = {
            orderId: item.id,
            consigneeRealname: item.address?.fullname,
            consigneeTelephone: item.address?.phone,
            consigneeAddress: item.address?.fullAddress,
            logisticsStatus: "100",
            logisticsCreateAt: dayjs().format("YYYY-MM-DD HH:mm"),
          };
          current[name] = key[1];
          _.push(current);
        }
      }
      return _;
    }, []);
    const resp = await jsonRequest.post("/api/request", {
      path: `/v1/shippingManifest/batch`,
      method: "post",
      application_key: "zy:services::mall",
      tenant_meta: currentTenant,
      data: { shippingManifestInsertParams: params },
    });
    const jsonData = await resp.json();
    if (jsonData.code === 0) {
      message.success(`批量发货成功`);
    }
  };

  const handleTableChange = (page: number, size: number) => {
    if (Object.keys(form.getFieldsValue()).length) {
      Modal.confirm({
        title: <span>操作提醒</span>,
        content: <p>切换分页前请提交当前填写内容，否则将被清空</p>,
        async onOk() {
          handlePageTo(page, size);
        },
      });
      return;
    }
    handlePageTo(page, size);
  };

  useImperativeHandle(ref, () => {
    return {
      handleSubmit, // bug https://www.yar2001.com/archives/1172
      isOwnValue() {
        return !!Object.keys(form.getFieldsValue())?.length;
      },
    };
  }, []);
  return (
    <Form form={form}>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        rowClassName="table-row"
        pagination={{
          size: "default",
          total: count,
          pageSize: pageSize,
          current: pageNumber,
          onChange: handleTableChange,
        }}
        size="middle"
        scroll={{ x: "max-content" }}
      />
    </Form>
  );
};

export default forwardRef(TableList);
