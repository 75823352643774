import {JsonEditor} from "jsoneditor-react18";
import ace from "brace";
import Ajv from "ajv";
import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";

const ajv = new Ajv({allErrors: true, verbose: true});

export default function ContentJson({ownerId, ownerType}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const responseRef: any = useRef()

    useEffect(() => {
        jsonRequest.post("/api/request", {
            path: `/v1/external/contents/${ownerType}/${ownerId}`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({data}: any) => {
                // 需要单独设置json编辑器的值
                responseRef.current.jsonEditor.set(data)
            })
    }, []);

    const jsonEditorProps = {
        mode: "code",
        schema: null,
        theme: "ace/theme/github",
        ajv: ajv,
        ace: ace,
        htmlElementProps: {style: {height: document.documentElement.clientHeight - 350}}
    }

    return <JsonEditor {...jsonEditorProps} ref={responseRef} mode="code" schema={null} value={{}}/>
}