import {useEffect, useRef, useState} from "react";
import MindElixir from 'mind-elixir'
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../../components/global";
import ContentImageUploader from "../../../components/ContentImageUploader";
import './ContentMindMap.css';
import CustomQuill from "../../../../components/CustomQuill";
import {Button, Flex, Space} from "antd";

export default function ContentMindMap({onCancel, onOk, initialValues}: any) {
    const mindRef = useRef<any>(null);
    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)
    const [editing, setEditing]: any = useState(false)
    const quillRef: any = useRef();

    useEffect(() => {
        const mind: any = new MindElixir({
            el: '#mind-map',
            locale: 'zh_CN',
            direction: MindElixir.RIGHT,
            before: {
                beginEdit(el) {
                    return false;
                },
            }
        })
        mindRef.current = mind;

        const initData = {nodeData: initialValues}
        mind.init(JSON.parse(JSON.stringify(initData)));

        mind.bus.addListener('selectNode', (node: any) => {
            setEditing(true)
            focusQuill();
        })
        mind.bus.addListener('unselectNode', (node: any) => {
            setEditing(false)
        })
        mind.bus.addListener('operation', (operation: any) => {
            if (operation.name === 'addChild') {
                operation.obj.dangerouslySetInnerHTML = `<p>${operation.obj.topic}</p>`
                mind.refresh(mind.getData())
            }
        })
    }, [])

    const onQuillChange = (v: any, text: any) => {
        const mind = mindRef.current;
        mind.currentNode.nodeObj.topic = text;
        mind.currentNode.nodeObj.dangerouslySetInnerHTML = v;
        mind.currentNode.innerHTML = v;
        mind.linkDiv();
    }

    const focusQuill = async () => {
        const mind = mindRef.current;
        const content = mind.currentNode.nodeObj.dangerouslySetInnerHTML || mind.currentNode.nodeObj.topic
        if (content) {
            // @ts-ignore
            document.querySelector('.mind-map-box .ql-editor').innerHTML = content;
        }
        await new Promise(resolve => setTimeout(resolve, 200));
        quillRef?.current.focus();
        quillRef?.current.setSelection(quillRef?.current.getText().length);
    }

    const onSave = () => {
        const mind = mindRef.current;
        const {nodeData} = mind.getData();
        onOk && onOk(nodeData);
    }

    const imgUploadApi = async (fd: any) => await ContentImageUploader(jsonRequest, currentTenant, fd.get('file'))

    return <div className='mind-map-box'>
        <div id='mind-map'/>
        <div className='quill-input' style={{display: editing ? 'flex' : 'none'}}>
            <CustomQuill imgUploadApi={imgUploadApi} quillRef={quillRef}
                         onChange={(v: any, t: any) => onQuillChange(v, t)}/>
        </div>
        <Flex justify='end' style={{marginTop: '20px'}}>
            <Space>
                <Button onClick={onCancel}>取消</Button>
                <Button type='primary' onClick={() => onSave()}>保存</Button>
            </Space>
        </Flex>
    </div>
}