import { Menu } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
// import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} selectedKeys={[pathName, fullPathName]} mode="inline" >
        <Menu.ItemGroup key="coin" title="应用">
            <Menu.Item key="/key/applications" icon={<UnorderedListOutlined />}>
                <Link to="/key/applications">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>应用列表</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/key/keys" icon={<UnorderedListOutlined />}>
                <Link to="/key/keys">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>公钥列表</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}



export default SideMenu