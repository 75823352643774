import {Button, Select, Space, Image, Flex, List, Tag} from "antd";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import React, {useEffect, useRef, useState} from "react";
import {ActionType, ProFormInstance, ProTable} from "@ant-design/pro-components";
import AudioPreview from "./AudioPreview";
import VideoPreview from "./VideoPreview";
import {Link} from "react-router-dom";

export default function ImportsForm({systemCode, mediaType, onImport}: any) {
    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)
    const tableRef = useRef<ActionType>();
    const formRef = useRef<ProFormInstance>();
    const [tenants, setTenants] = useState<any>([]);
    const [mediaName, setMediaName]: any = useState<string>(mediaType);

    useEffect(() => {
        getTenants();
        getMediaTypes();
    }, []);

    const getTenants = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/systems",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({data}: any) => setTenants(data))
    }

    const getMediaTypes = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type: 'media_type'},
        }).then((resp: any) => resp.json())
            .then(({data}: any) => setMediaName(data.find((e: any) => e.enumKey === mediaType)?.enumName || mediaType))
    }

    const PicturePreview = ({data}: any) => {
        const {pictureName, description, picturePath} = data || {};
        return <Flex align='center' justify='left'>
            <Image width={80} src={picturePath}/>
            <Space direction='vertical' style={{marginLeft: '20px'}}>
                <b>{pictureName}</b>
                <div style={{maxWidth: '400px', textWrap: 'wrap'}}>{description}</div>
            </Space>
        </Flex>
    }

    const MediaInlinePreview = ({media}: any) => {
        const {mediaName, mediaType, extData} = media;
        switch (mediaType) {
            case "string":
                return <span className='three-line-ellipsis'>{extData?.description}</span>
            case "rich_text":
                return <div dangerouslySetInnerHTML={{__html: extData?.description}}/>
            case "picture":
                return <PicturePreview data={extData}/>
            case "multi_picture":
            case "slide":
                const {pictures} = extData;
                return <List itemLayout='vertical' dataSource={pictures || []}
                             renderItem={(item: any) => <PicturePreview data={item}/>}/>
            case "audio":
                return <AudioPreview {...extData}/>
            case "multi_audio":
                const {audios} = extData;
                return <List itemLayout='vertical' dataSource={audios || []}
                             renderItem={(item: any) => <AudioPreview {...item}/>}/>
            case "video":
                return <VideoPreview {...extData}/>
            case "multi_video":
                const {videos} = extData;
                return <List itemLayout='vertical' dataSource={videos || []}
                             renderItem={(item: any) => <VideoPreview {...item}/>}/>
            case "3D":
                const data = {
                    pictureName: extData?.modelName,
                    description: extData?.description,
                    picturePath: extData?.picturePath,
                }
                return <PicturePreview data={data}/>
            case "tags":
            case "tags_content":
                return <Space wrap>
                    {extData?.tags?.map(({name}: any) => <Tag>{name}</Tag>)}
                </Space>
            case "inter_link":
            case "exter_link":
                const {links} = extData;
                return <Space>
                    {links?.map(({linkName, linkPath}: any) => <Link to={linkPath}>{linkName}</Link>)}
                </Space>
            case "poetry":
                const {description, audio} = extData;
                return <AudioPreview audioPath={audio.url} description={description}/>
        }
        return <span className='three-line-ellipsis'>{mediaName}</span>
    }

    const doMediaImport = (media: any, type: string) => {
        onImport && onImport({type, media});
    }

    const columns: any[] = [
        {
            title: '媒体类型',
            key: 'mediaType',
            dataIndex: 'mediaType',
            hideInTable: true,
            colSize: 24,
            renderFormItem: () => <span>{mediaName}</span>
        },
        {
            title: '所属体系',
            dataIndex: 'systemCode',
            align: 'center',
            initialValue: systemCode,
            hideInTable: true,
            renderFormItem: () => <Select options={tenants.map((e: any) => ({
                value: e.systemCode,
                label: e.systemName
            }))}/>
        },
        {
            title: '媒体ID',
            key: 'mediaId',
            dataIndex: 'mediaId',
            align: 'center',
            width: 100,
            search: false,
            ellipsis: true,
        },
        {
            title: '租户',
            dataIndex: 'tenement',
            align: 'center',
            width: 100,
            search: false,
        },
        {
            title: '所属内容',
            key: 'contentName',
            dataIndex: 'contentName',
            align: 'center',
            width: 100,
        },
        {
            title: '媒体标题',
            key: 'mediaName',
            dataIndex: 'mediaName',
            align: 'center',
            width: 200,
        },
        {
            title: '媒体内容',
            dataIndex: '',
            align: 'center',
            search: false,
            render: (_: any, record: any) => <MediaInlinePreview media={record}/>,
        },
        {
            title: '导入方式',
            key: 'option',
            search: false,
            align: 'center',
            width: 150,
            render: (_: any, record: any) => <Space>
                <Button type="link" size='small' onClick={() => doMediaImport(record, 'ref')}>引用</Button>
                <Button type="link" size='small' onClick={() => doMediaImport(record, 'copy')}>复制</Button>
            </Space>,
        },
    ]

    const request = async (params: any) => {
        const {current, ...others} = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/v1/contents/medias/imports",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {
                ...others,
                mediaType,
                pageNumber: current,
            },
        }).then((resp: any) => resp.json())
        const {data} = result;
        return {data: data.list, success: result.code === 0, total: data.total};
    }

    return <div className='imports-table'>
        <ProTable
            rowKey='id'
            actionRef={tableRef}
            formRef={formRef}
            cardBordered
            columns={columns}
            request={request}
            pagination={{defaultPageSize: 5, showSizeChanger: true}}
            search={{span: 6, defaultCollapsed: false}}
        />
    </div>
}