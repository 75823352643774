import {ActionType, ProTable} from "@ant-design/pro-components";
import {Button, Form, message, Modal, Select, Tag} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import GlobalWrap from "../../components/globalWrap";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../components/global";
import {useEffect, useRef, useState} from "react";
import FieldForm from "./components/FieldForm";
import FieldTempForm from "./components/FieldTempForm";

export default function Field(params: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [form] = Form.useForm();
    const [tempForm] = Form.useForm();
    const tableRef = useRef<ActionType>();

    const [tenants, setTenants] = useState<any>([]);
    const [fieldTypes, setFieldTypes] = useState<any>([]);
    const [mediaTypes, setMediaTypes] = useState<any>([]);

    useEffect(() => {
        getTenants();
        getFieldTypes();
        getMediaTypes();
        tableRef.current?.reload();
    }, [currentTenant]);

    const getTenants = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/systems/tenant",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setTenants(data)
            })
    }

    const getFieldTypes = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type: 'field_type'},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setFieldTypes(data)
            })
    }

    const getMediaTypes = () => {
        jsonRequest.post("/api/request", {
            path: "/v1/enums",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type: 'media_type'},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                setMediaTypes(data)
            })
    }

    const columns: any[] = [
        {
            title: '上级名称',
            dataIndex: 'parentName',
            search: false,
            align: 'center',
        },
        {
            title: '名称',
            dataIndex: 'fieldName',
            search: false,
            align: 'center',
        },
        {
            title: '字段key',
            dataIndex: 'fieldKey',
            search: false,
            copyable: true,
            align: 'center',
        },
        {
            title: '所属体系',
            dataIndex: 'systemName',
            align: 'center',
            search: false,
        },
        {
            title: '所属体系',
            dataIndex: 'systemCode',
            align: 'center',
            hideInTable: true,
            renderFormItem: () => <Select allowClear
                                          options={tenants.map((e: any) => ({
                                              value: e.systemCode,
                                              label: e.systemName
                                          }))}/>
        },
        {
            title: '字段类型',
            dataIndex: 'fieldType',
            align: 'center',
            renderFormItem: () => <Select allowClear
                                          options={fieldTypes.map((e: any) => ({
                                              value: e.enumKey,
                                              label: e.enumName
                                          }))}/>,
            render: (_: any, record: any) => <>
                <span>{fieldTypes.find((e: any) => e.enumKey === record.fieldType)?.enumName || record.fieldType}</span>
            </>
        },
        {
            title: '关键词',
            dataIndex: 'keyword',
            hideInTable: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            search: false,
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            key: 'option',
            search: false,
            align: 'center',
            width: 400,
            render: (_: any, record: any) => <>
                {['content', 'content_cate_info'].indexOf(record.fieldType) !== -1 &&
                    <Button type="link" onClick={() => handleAddOrEdit({}, record)}>添加下级字段</Button>
                }
                {['content', 'content_cate_info'].indexOf(record.fieldType) !== -1 && record.parentId &&
                    <Button type="link" onClick={() => handleAddOrEdit({}, {
                        id: record.parentId, fieldName: record.parentName, fieldKey: record.parentKey,
                        systemCode: record.systemCode, fieldType: record.fieldType,
                    })}>添加同级字段</Button>
                }
                <Button type="link" onClick={() => handleAddOrEdit(record)}>修改</Button>
            </>,
        }
    ];

    const handleQuickFieldTemp = async (detail: any) => {
        Modal.confirm({
            width: 1000,
            title: <span>字段模板编辑</span>,
            content: <GlobalWrap>
                <FieldTempForm form={tempForm} tenants={tenants} mediaTypes={mediaTypes} fieldTypes={fieldTypes}
                               detail={detail}/>
            </GlobalWrap>,
            onOk: (close) => {
                tempForm.validateFields()
                    .then(async (values) => {
                        const {data} = await jsonRequest.post("/api/request", {
                            path: `/v1/system-fields/temp`,
                            method: values.id ? "put" : "post",
                            application_key: "zy:application::knowledge-foundation",
                            tenant_meta: currentTenant,
                            data: values,
                        }).then(resp => resp.json())
                        if (!data.error) {
                            // @ts-ignore
                            tableRef.current.reload();
                            message.success(`${detail?.id ? '编辑' : '新增'}成功`)
                            close();
                        } else {
                            message.error(data.error);
                        }
                    })
                    .catch(_ => _)
                return false
            },
            onCancel: (close) => {
                tempForm.resetFields();
                close();
            },
        })
    }

    const handleAddOrEdit = async (detail: any, parent: any = {}) => {
        const parentId = detail.parentId || (parent.fieldType === 'content_cate_info' ? parent.parentId : parent.id)
        const model = Modal.confirm({
            width: 600,
            title: <span>字段编辑 - {detail?.id ? '编辑' : '新增'}</span>,
            content: <GlobalWrap>
                <FieldForm form={form} tenants={tenants} types={fieldTypes}
                           detail={{
                               parentId: {value: parentId},
                               fieldType: detail.fieldType || parent.fieldType,
                               systemCode: detail.systemCode || parent.systemCode,
                               ...detail
                           }}
                           parent={parent}
                           quickPushChild={() => {
                               handleAddOrEdit({}, form.getFieldsValue());
                               model.destroy();
                           }}/>
            </GlobalWrap>,
            onOk: (close) => {
                form.validateFields()
                    .then(async (values) => {
                        const {parentId, ...others} = values;
                        const detailId = form.getFieldValue('id')
                        const {data} = await jsonRequest.post("/api/request", {
                            path: `/v1/system-fields`,
                            method: detailId ? "put" : "post",
                            application_key: "zy:application::knowledge-foundation",
                            tenant_meta: currentTenant,
                            data: {parentId: parentId?.value, ...others},
                        }).then(resp => resp.json())
                        if (!data.error) {
                            // @ts-ignore
                            tableRef.current.reload();
                            message.success(`${detailId ? '编辑' : '新增'}成功`)
                            if (!detailId && data.id) {
                                form.setFieldValue('id', data.id);
                                form.setFieldValue('quick', true);
                                const {systemCode, fieldType} = values;
                                handleQuickFieldTemp({
                                    fieldId: data.id,
                                    groupName: parentId?.label,
                                    systemCode,
                                    fieldType,
                                    quick: true
                                });
                            } else {
                                close();
                            }
                        } else {
                            message.error(data.error);
                        }
                    })
                    .catch(_ => _)
                return false
            },
            onCancel: (close) => close(),
            afterClose: () => form.resetFields()
        })
    }

    const request = async (params: any, sort: any, filter: any) => {
        const {current} = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/v1/system-fields/paged",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {...params, pageNumber: current},
        }).then((resp: any) => resp.json())
        const {data} = result;
        return {data: data.list, success: result.code === 0, total: data.total};
    }

    return (
        <>
            <h1>字段维护</h1>
            <ProTable
                rowKey="id"
                actionRef={tableRef}
                cardBordered
                columns={columns}
                request={request}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                search={{span: 6, defaultCollapsed: false}}
                toolBarRender={() => [<Button
                    key="button"
                    icon={<PlusOutlined/>}
                    onClick={() => handleAddOrEdit({})}
                    type="primary"
                >
                    新增
                </Button>]}
            />
        </>
    )
}