import {Button, Form, Input, List, Modal, Pagination, Tag, Image, Divider, Switch, Popconfirm} from "antd"
import {selectJsonRequest, selectCurrentTenant} from "../../components/global"
import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {
    FieldTimeOutlined,
    MoneyCollectOutlined,
    ProductFilled,
    PlusCircleTwoTone,
    DeleteOutlined
} from "@ant-design/icons"
import dayjs from "dayjs"
import "./Product.css"
import GlobalWrap from "../../components/globalWrap"
import ProductForm from "./components/ProductForm"

export default function Product(params: any) {
    const [data, setData]: any = useState({})
    const [loading, setLoading] = useState(true)
    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)
    // 搜索表单
    const [queryForm] = Form.useForm()
    // 产品表单
    const [productForm] = Form.useForm()
    // 当前页
    const [pageNumber, setPageNumber] = useState(1)
    // 当前页容量
    const [pageSize, setPageSize] = useState(12)
    const [currencies, setCurrencies]: any = useState([]);

    useEffect(() => {
        handlePageTo(pageNumber, pageSize)
        getCurrencies();
    }, [currentTenant])

    const getCurrencies = () => {
        jsonRequest.post(`/api/request`, {
            path: `/api/currency`,
            method: "get",
            application_key: "zy:services::finance",
            tenant_meta: currentTenant,
            data: {}
        })
            .then((resp: any) => resp.json())
            .then(({data}: any) => setCurrencies(data))
    }

    // 跳转分页数据
    const handlePageTo = (pageNumber: number, pageSize: number, queryData: any = null) => {
        setPageNumber(pageNumber)
        setPageSize(pageSize)
        queryData = queryData || queryForm.getFieldsValue()
        // channel.push("page-to", { page, size })
        if (!currentTenant) return;

        jsonRequest.post("/api/request", {
            path: `/v1/product/`,
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {...queryData, tenantMeta: currentTenant, page: pageNumber, pageSize: pageSize}
        })
            .then((resp: any) => resp.json())
            .then(async ({data}: any) => {
                setLoading(false)
                setData(data)
            })
    }

    const deleteProduct = async (id: any) => {
        const res = await jsonRequest.post("/api/request", {
            path: `/v1/product/${id}`,
            method: "delete",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {id}
        }).then((resp: any) => resp.json())
        handlePageTo(pageNumber, pageSize, queryForm.getFieldsValue())
    }

    const handleProductEditOrCreate = async (product: any) => {
        Modal.confirm({
            width: 1200,
            title: !!product.productName ? `商品编辑 - ${product.productName}` : "商品新增",
            content: <GlobalWrap>
                <ProductForm form={productForm} product={product} currencies={currencies}/>
            </GlobalWrap>,
            onOk: (close) => {
                productForm.validateFields()
                    .then(async (values) => {
                        console.log(values)
                        const data = {
                            ...values,
                            skuId: values.productSku[0].id,
                            currencyName: currencies.find((e: any) => e.meta === values.currencyMeta)?.name,
                            // productSku: {
                            //     ...values.productSku,
                            //     currencyMeta: values.currencyMeta,
                            //     currencyName: currencyMap[values.currencyMeta],
                            //     // externalCallbackParamsStr: JSON.parse(values.productSku.externalCallbackParamsStr || {}),
                            // },
                            tags: values.tags?.length > 0 ? values.tags?.join(",") : "",
                        }

                        if (!!product?.id)
                            await jsonRequest.post("/api/request", {
                                path: `/v1/product/${product.id}`,
                                method: "post",
                                application_key: "zy:services::mall",
                                tenant_meta: currentTenant,
                                data
                            })
                        else
                            await jsonRequest.post("/api/request", {
                                path: `/v1/product/`,
                                method: "post",
                                application_key: "zy:services::mall",
                                tenant_meta: currentTenant,
                                data
                            })

                        handlePageTo(pageNumber, pageSize, queryForm.getFieldsValue())

                        close()
                    })
                    .catch(e => {
                        console.log(e)
                    })

                return false
            },
            onCancel: (close) => {
                productForm.resetFields();
                close();
            }
        })
    }

    // 搜索
    const handleFilter = (values: any) => {
        handlePageTo(1, pageSize, values)
    }

    // 控制上架下架
    const handleShelf = (product: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/product/${product.id}/${!product.sale}/shelf`,
            method: "post",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {}
        })
            .then((resp: any) => resp.json())
            .then(async ({data}: any) => {
                handlePageTo(pageNumber, pageSize)
            })
    }

    // 订单操作按钮
    const productOperations = (product: any) => {
        // const refundButton = <Button size="small" onClick={() => handleOrderRefund(order.transactionId)}>退款</Button>
        const isSaleSwitch = <Switch checkedChildren="上架" unCheckedChildren="下架"
                                     onClick={() => handleShelf(product)} value={product.sale}></Switch>
        const editButton = <Button size="small" type="primary"
                                   onClick={() => handleProductEditOrCreate(product)}>编辑</Button>

        switch (product.sale) {
            case true:
                return <div className='product-operations'>
                    {editButton}
                    <Divider type="vertical"/>
                    {isSaleSwitch}
                </div>
            case false:
                return <div className='product-operations'>
                    {editButton}
                    <Divider type="vertical"/>
                    {isSaleSwitch}
                </div>

            default:
                break;
        }

    }

    return <div className="page mall-product">
        <h1 style={{display: "flex", alignItems: "center"}}>商品列表 <Button
            onClick={() => handleProductEditOrCreate({})} type="link" size="small"
            icon={<PlusCircleTwoTone/>}>添加商品</Button></h1>

        <div className="content">
            <Form form={queryForm} size="small" autoComplete="off" layout="inline" onFinish={handleFilter}
                  initialValues={{status: null}}>
                <Form.Item name="transactionId" label="商品名" style={{minWidth: 300}}>
                    <Input placeholder="模糊匹配"/>
                </Form.Item>
                <Button.Group>
                    <Button type="primary" htmlType="submit">查询</Button>
                    <Button onClick={() => {
                        queryForm.resetFields([{status: null}])
                        handleFilter({})
                    }} htmlType="reset">清空</Button>
                </Button.Group>
            </Form>

            <br/>
            <List dataSource={data.data}
                  loading={loading}
                  rowKey={d => d.transactionId}
                  grid={{gutter: 16, column: 4}}
                  itemLayout="vertical"
                  size="large"
                  renderItem={(item: any) => (
                      <List.Item key={item.id} className="item-product" extra={<>
                          <div>
                              <span
                                  style={{color: "#aaa"}}><FieldTimeOutlined/> {dayjs.tz(item.updateAt).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm')}</span>
                          </div>
                          {productOperations(item)}
                      </>}>
                          <div className="content">
                              <div className="header">
                                  <span className="cate-product-name">
                                      <Tag>{item.categoryName}</Tag>
                                      <span className="productName">
                                          <ProductFilled style={{color: "rgb(22, 119, 255)"}}/>
                                          {item.productName}
                                      </span>
                                  </span>
                                  <span className="cate-product-tools">
                                      <Popconfirm
                                          title="确定删除？"
                                          onConfirm={() => deleteProduct(item.id)}
                                          okText="是"
                                          cancelText="否"
                                      >
                                        <Button type='link' danger><DeleteOutlined/></Button>
                                        </Popconfirm>
                                  </span>
                              </div>
                              <div>
                                  <span style={{color: "#eb2f96", display: 'flex', justifyContent: 'space-between'}}>
                                      <span>
                                          <MoneyCollectOutlined/>
                                          {item.productSkuDTO.productPrice}
                                      </span>
                                      <span>
                                          <Tag color='#eb2f96' bordered={false}>库存</Tag>
                                          {item.productSkuDTO.productStock}
                                      </span>
                                  </span>
                              </div>
                              <div>
                                  {item.type === 1 && <Tag color="#eb2f96">虚拟商品</Tag>}
                                  {item.tags &&
                                      <span>{item.tags?.split(",").map((tag: string) => <Tag
                                          key={tag}>{tag}</Tag>)}</span>}
                              </div>
                              <div className="summary">{item.summary}</div>
                              <div className="product-images">
                                  <div className="item-image">
                                      {item.productSkuDTO?.images?.map((img: any, index: any) =>
                                          index < 3 &&
                                          <Image className="img" src={img.objName} width={80} key={img.id}/>
                                      )}
                                  </div>
                              </div>
                          </div>
                      </List.Item>
                  )}
            >
            </List>
            <Pagination total={data.count} pageSize={pageSize} current={pageNumber}
                        onChange={(page, size) => handlePageTo(page, size)}></Pagination>
        </div>
    </div>
}
