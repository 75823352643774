import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../components/global";
import {useEffect, useState} from "react";
import {Alert, Button, Empty, message, Steps} from "antd";
import './ExpressInfo.css';
import {CopyTwoTone} from "@ant-design/icons";
import dayjs from "dayjs";

export default function ExpressInfo({id, maxHeight}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [data, setData]: any = useState()

    useEffect(() => getExpress(), []);

    const getExpress = () => {
        jsonRequest.post("/api/request", {
            path: `/v1/order/${id}/express`,
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {}
        })
            .then(resp => resp.json())
            .then(({data}: any) => {
                setData(data)
            })
    }

    const statusMap: any = {
        'WAIT_ACCEPT': '待揽收',
        'ACCEPT': '已揽收',
        'TRANSPORT': '运输中',
        'DELIVERING': '派件中',
        'AGENT_SIGN': '已代签收',
        'SIGN': '已签收',
        'FAILED': '包裹异常'
    }

    const details = data?.logisticsTraceDetails;

    const copy = (str: string) => {
        navigator.clipboard.writeText(str)
            .then(() => {
                message.success({content: '已复制到剪切板中', key: "exporting", duration: 3})
            })
            .catch(() => {
                message.error({content: '写入剪切板错误', key: "exporting", duration: 3})
                navigator.clipboard.writeText("")
            })

    }

    return <>
        <div className="order-express-box" style={{maxHeight: maxHeight ? `${maxHeight}px` : 'auto'}}>
            {!data?.number ? <Empty description='暂无物流信息'/> :
                <div className='express-body'>
                    <Alert onClick={() => copy(data.number)} message={<div className='express-header'>
                        <div>
                            <span style={{marginRight: '6px'}}>{data.expressCompanyName}</span>
                            {data.number}
                        </div>
                        <Button type="link" size="small" icon={<CopyTwoTone/>}>点击复制</Button>
                    </div>}/>
                    <div className='express-detail'>
                        <Steps direction='vertical' progressDot={true}
                               items={details?.reverse().map((e: any, index: any) => {
                                   const statusNode = index == 0 || (index > 0 && details[index].logisticsStatus != details[index - 1].logisticsStatus)
                                   return {
                                       title: statusNode ? statusMap[e.logisticsStatus] : null,
                                       description: <>
                                           <div>{e.desc}</div>
                                           <div>{dayjs.tz(e.time).tz(dayjs.tz.guess()).format("YYYY-MM-DD HH:mm")}</div>
                                       </>,
                                       status: 'finish',
                                   }
                               })}/>
                    </div>
                </div>}
        </div>
    </>
}