import {Form, Input, message} from "antd";
import FileInput from "./FileInput";
import React from "react";
import OrderedMedia from "../../content/components/OrderedMedia";

export default function AudioInput({form, prefix, fullPrefix, field, index, listPathName}: any) {
    const {isRequired} = field;

    const onFileChange = (file: any) => {
        if (!file) return;
        const audioName = form.getFieldValue([...fullPrefix, 'audioName']);
        if (!audioName) {
            const name = file.name.substring(0, file.name.lastIndexOf("."));
            form.setFieldValue([...fullPrefix, 'audioName'], name)
        }
        setAudioDuration(file.url);
    }

    const setAudioDuration = (url: any) => {
        const audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", (e) => {
            const time = Math.floor(audioElement.duration) || 0;
            form.setFieldValue([...fullPrefix, 'duration'], time)
        })
        audioElement.addEventListener("error", (e) => {
            message.error('获取音频时长失败');
        })
    }

    const InputBody = () => <>
        <Form.Item label={`音频标题`} name={[...prefix, "audioName"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入音频标题"/>
        </Form.Item>
        <Form.Item label={`音频描述`} name={[...prefix, "description"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入音频描述"/>
        </Form.Item>
        <FileInput form={form} labelPre="音频" prefix={prefix} fullPrefix={fullPrefix}
                   type="audio" accept='audio/*' field={field} namePre="audio" onChange={onFileChange}/>
        <Form.Item label={`音频时长（秒）`} name={[...prefix, "duration"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入音频时长"/>
        </Form.Item>
        <FileInput form={form} labelPre="字幕" prefix={prefix} fullPrefix={fullPrefix}
                   type="other" field={field} namePre="subtitle"/>
    </>

    return listPathName ? <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <InputBody/>
    </OrderedMedia> : <InputBody/>
}