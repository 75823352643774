import { Button, Form, Input, Popover, Radio } from "antd";
import { useSelector } from "react-redux";
import { selectJsonRequest, selectCurrentTenant } from "../components/global";
import { useState } from "react";

export default function KeyGenerationForm({ application, ...props }: any) {

    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [generationForm] = Form.useForm();

    const [data, setData]: any = useState(null)
    // 密钥显示类型
    const [keyType, setKeyType]: any = useState(null)

    const handleSubmit = (params: any) => {
        if (!currentTenant) return;

        jsonRequest.post(`/api/request/api/key/${params.type}/gen`, { method: "get", application_key: "zy:services::keys", tenant_meta: currentTenant, data: params })
            .then(resp => resp.json())
            .then(({ data }) => {
                setData(data)

                // 设置key类型
                setKeyType("pem")
            })
    }

    const handleKeyTypeChanged = (type: string) => {
        setKeyType(type)
    }

    return <div style={{ display: "flex" }}>
        <div style={{ flex: 0.5 }}>
            <Form onFinish={handleSubmit} name="basic" on form={generationForm} initialValues={{ application }} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" {...props}>
                <Form.Item label="应用服务名" name={["application", "name"]} rules={[{ required: true, message: '不能为空' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="应用服务Key" name={["application", "key"]} rules={[{ required: true, message: '不能为空' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="应用服务Host" name={["application", "host"]} >
                    <Input />
                </Form.Item>
                <Form.Item label="密钥类型" name="type" rules={[{ required: true, message: '原始密码能为空' }]} initialValue="signature">
                    <Radio.Group optionType="button" buttonStyle="solid" size="small">
                        <Radio value="signature">签名</Radio>
                        <Radio value="encryption">加密</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button.Group>
                        <Button type="primary" htmlType="submit">生成</Button>
                        {keyType === null && <Button onClick={props.finish}>取消</Button>}
                    </Button.Group>
                </Form.Item>
            </Form>
        </div>
        <div style={{ flex: 1 }}>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item label="显示类型">
                    <Radio.Group size="small" disabled={keyType === null} value={keyType} optionType="button" buttonStyle="solid" onChange={(e) => handleKeyTypeChanged(e.target.value)}>
                        <Radio value="pem">PEM格式</Radio>
                        <Radio value="base64">Base64格式</Radio>
                        <Radio value="json">JSON格式</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="公钥">
                    <Input.TextArea disabled placeholder="---------- 等待生成 ----------" autoSize value={data?.[keyType]?.pk} style={{ fontSize: 12 }} />
                </Form.Item>
                <Form.Item label="私钥">
                    <Input.TextArea disabled placeholder="---------- 等待生成 ----------" autoSize value={data?.[keyType]?.sk} style={{ fontSize: 12 }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Popover content={<Button type="primary" danger onClick={props.finish} >确定</Button>}>
                        <Button type="primary" danger>已记录，关闭</Button>
                    </Popover>
                </Form.Item>
            </Form>
        </div>
    </div>
}