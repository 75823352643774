import {Form, Input} from "antd";
import {useEffect} from "react";

export default function SystemForm({form, detail}: any) {

    useEffect(() => {
        form.resetFields()
    }, []);

    return (
        <Form form={form} initialValues={detail} size="small" name="basic"
              labelCol={{span: 6}} wrapperCol={{span: 14}}>
            <Form.Item label="ID" name="id"
                       style={{display: "none"}}>
                <Input disabled/>
            </Form.Item>
            <Form.Item label="名称" name="systemName" rules={[{required: true, message: '不能为空'}]}>
                <Input/>
            </Form.Item>
            {!detail.id && <Form.Item label="编码key" name="systemCode" rules={[{required: true, message: '不能为空'}]}>
                <Input/>
            </Form.Item>}
        </Form>
    )
}