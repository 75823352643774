import {Button, Flex, Space} from "antd";
import React, {useRef, useState} from "react";
import {MutedOutlined, SoundOutlined} from "@ant-design/icons";

export default function AudioPreview({audioName, description, audioPath}: any) {
    const [playing, setPlaying]: any = useState<boolean>(false);
    const ref: any = useRef();

    const toggleAudio = () => {
        if (ref.current.paused) {
            ref.current.play();
            setPlaying(true)
        } else {
            ref.current.pause();
            setPlaying(false)
        }
    }

    return <Flex align='center'>
        <Button shape='circle' onClick={() => toggleAudio()}
                icon={playing ? <SoundOutlined/> : <MutedOutlined/>}/>
        <audio src={audioPath} ref={ref}>
            你的浏览器不支持音频播放
        </audio>
        <Space direction='vertical' style={{marginLeft: '20px'}}>
            <b>{audioName}</b>
            <div className='three-line-ellipsis' style={{maxWidth: '400px', textWrap: 'wrap'}}>{description}</div>
        </Space>
    </Flex>
}