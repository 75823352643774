
import { Table, Form, DatePicker, Button } from "antd";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from 'react-redux';
import dayjs from "dayjs";
import { selectJsonRequest, selectCurrentTenant } from "../components/global";
// import UserAvatar from "../components/user/UserAvatar"

function DistributedAccountFlow(props: any) {

    const [data, setData]: any = useState({})
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState({})
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    let [form] = Form.useForm()

    useEffect(() => {
        handlePageTo(1, 20, {})

        return () => {
        }
    }, [currentTenant])

    const handlePageTo = (page_number: number, page_size: number, query: any) => {

        if (!currentTenant) return;

        jsonRequest.post("/api/request/api/account/flow", { method: "get", application_key: "zy:services::finance", tenant_meta: currentTenant, data: { page_number, page_size, ...query }, })
            .then(resp => resp.json())
            .then(({ data }) => {
                setLoading(false)
                setData(data)
            })
        // channel.push("page-to", { page, size })
    }

    const handleQuery = (values: any) => {
        setQuery(values)
        handlePageTo(1, 20, values)
        // channel.push("query", values)
    }

    return <div className="page coin-code-table">
        <h1>财务（积分）服务 - 账户流水</h1>

        <div style={{ marginBottom: 20 }}>
            <Form form={form} layout="inline" onFinish={handleQuery} initialValues={query}>
                <Form.Item label="产生时间段" name="occur_at">
                    <DatePicker.RangePicker></DatePicker.RangePicker>
                </Form.Item>

                <Button.Group>
                    <Button type="primary" htmlType="submit">查询</Button>
                    <Button onClick={() => handleQuery({})} htmlType="reset">清空</Button>
                </Button.Group>
            </Form>
        </div>

        <div>
            <Table dataSource={data?.entries} loading={loading}
                rowKey={d => d.transaction_id?.toString()} pagination={{
                    onChange: (page, size) => { handlePageTo(page, size, query) },
                    total: data.total_count,
                    current: data.page_number,
                    pageSize: data.page_size,
                    showQuickJumper: true
                }} scroll={{ y: document.documentElement.clientHeight - 380 }}>
                {/* <Table.Column width={100} align="center" title="用户" dataIndex="owner" key="owner" render={v => !!v && <UserAvatar nickname={v.nickname} size={40} avatar={v.avatar} id={v.id} />} /> */}
                <Table.Column align="left" title="持有人类型" dataIndex="owner_type" key="owner_type" />
                <Table.Column align="left" title="持有人ID" dataIndex="owner_id" key="owner_id" />
                <Table.Column width={100} align="center" title="货币" dataIndex={["currency", "name"]} key="currency-name" />
                <Table.Column width={200} align="center" title="金额" dataIndex="amount" key="amount" render={(v, i: any) => `${v} ${i.currency?.unit}`} />
                <Table.Column width={200} align="center" title="入账后余额" dataIndex="balance" key="balance" render={(v, i: any) => `${v} ${i.currency?.unit}`} />
                <Table.Column align="left" title="交易渠道" dataIndex="trade_channel_meta" key="trade_channel_meta" />
                <Table.Column align="left" title="简要" dataIndex="summary" key="summary" />
                <Table.Column width={200} align="center" title="产生时间" dataIndex="occur_at" key="occur_at" render={(v) => !!v && dayjs(v * 1000).format("YYYY-MM-DD HH:mm")} />
            </Table>
        </div>
    </div>
}



export default DistributedAccountFlow;