import {Button, Form, Input, UploadFile} from "antd";
import KnowledgeUploader from "../KnowledgeUploader";
import {UploadOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant} from "../../../components/global";

export default function FileInput({
                                      form,
                                      labelPre,
                                      prefix = [],
                                      fullPrefix = [],
                                      namePre,
                                      type,
                                      field,
                                      pathName,
                                      onChange,
                                      accept,
                                  }: any) {
    const {isRequired} = field || {};
    const currentTenant = useSelector(selectCurrentTenant)
    const [fileList, setFileList]: any = useState<UploadFile[]>([])

    const pathInputName = pathName ? pathName : namePre ? `${namePre}Path` : "path";
    const sizeInputName = namePre ? `${namePre}Size` : "size";
    const fileInputName = namePre ? `${namePre}File` : "file";

    useEffect(() => {
        const path = form.getFieldValue([...fullPrefix, pathInputName])
        const file = form.getFieldValue([...fullPrefix, fileInputName])
        if (file || !path) return;

        const fileName = path.substring(path.lastIndexOf("/") + 1);
        const list: any = [{name: fileName, url: path, uid: -(new Date()).getTime(), status: 'done'}];
        form.setFieldValue([...fullPrefix, fileInputName], list)
        setFileList(list)
    }, []);

    const bytesToSize = (size: any) => {
        if (size < 0.1 * 1024) {
            //小于0.1KB，则转化成B
            size = size.toFixed(2) + 'B'
        } else if (size < 0.1 * 1024 * 1024) {
            // 小于0.1MB，则转化成KB
            size = (size / 1024).toFixed(2) + 'KB'
        } else if (size < 0.1 * 1024 * 1024 * 1024) {
            // 小于0.1GB，则转化成MB
            size = (size / (1024 * 1024)).toFixed(2) + 'MB'
        } else {
            // 其他转化成GB
            size = (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
        }

        // 转成字符串
        let sizeStr = size + '',
            // 获取小数点处的索引
            index = sizeStr.indexOf('.'),
            // 获取小数点后两位的值
            dou = sizeStr.substr(index + 1, 2)

        // 判断后两位是否为00，如果是则删除00
        if (dou == '00')
            return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)

        return size
    }

    const handleFileChange = (fileList: any) => {
        setFileList(fileList)
        const [file] = fileList;
        form.setFieldValue([...fullPrefix, pathInputName], file?.url || '')
        form.setFieldValue([...fullPrefix, sizeInputName], file?.size || '')
        file?.status === 'done' && onChange && onChange(file)
    }

    return <>
        <Form.Item label={`${labelPre}文件`} rules={[{required: isRequired, message: '不能为空'}]}
                   name={[...prefix, fileInputName]}>
            <KnowledgeUploader path={[currentTenant, "product"].join("/")} type={type} maxCount={1}
                               value={fileList} onChange={handleFileChange} accept={accept}>
                {fileList.length === 0 && <Button icon={<UploadOutlined/>}>点击上传</Button>}
            </KnowledgeUploader>
        </Form.Item>
        <Form.Item dependencies={[[...prefix, sizeInputName]]} label={`${labelPre}文件大小`}>
            {() =>
                <span>{bytesToSize(form.getFieldValue([...fullPrefix, sizeInputName]) || 0)}</span>}
        </Form.Item>
        <Form.Item hidden label={`${labelPre}文件大小`} name={[...prefix, sizeInputName]}>
            <Input/>
        </Form.Item>
        <Form.Item hidden label={`${labelPre}文件地址`} name={[...prefix, pathInputName]}>
            <Input/>
        </Form.Item>
    </>
}