import { KeyOutlined, PlusCircleTwoTone, UserOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, List, Modal, Pagination, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectJsonRequest } from "../components/global";
import GlobalWrap from "../components/globalWrap";
import "./UserList.css"
import GrantRoles from "./components/GrantRoles";

export default function UserList(params: any) {
    const [data, setData]: any = useState({})
    const [loading, setLoading] = useState(true)
    const jsonRequest = useSelector(selectJsonRequest)
    const [userForm] = Form.useForm()
    const [grantUserForm] = Form.useForm()


    useEffect(() => {
        handleLoad()

        return () => { }
    }, [])

    const handleLoad = async (page: number = 1, size: number = 20) => {
        const responseJson = (await jsonRequest.get("/api/user", { page, size })).json()
        setData((await responseJson).data)
        setLoading(false)
    }

    const handleCreateUser = () => {
        Modal.confirm({
            width: 400,
            title: '创建操作人',
            content: <GlobalWrap>
                <Form form={userForm} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
                    <Form.Item label="操作人姓名" name="name" rules={[{ required: true, message: '不能为空' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="账号" name={["evidence", "account"]} rules={[{ required: true, message: '不能为空' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="密码" name={["evidence", "password"]} rules={[{ required: true, message: '不能为空' }]}>
                        <Input.Password />
                    </Form.Item>

                </Form>
            </GlobalWrap>,
            onOk: async (close) => {
                let values = await userForm.validateFields()

                if ((await jsonRequest.post("/api/user", values)).ok) {
                    message.success("创建完成")
                    userForm.resetFields()
                    handleLoad()
                    close()
                } else {
                    message.error("创建失败")
                }

                return true
            },
        })
    }


    const handleGrantRoles = (user: any) => {

        Modal.confirm({
            width: 400,
            title: '设置角色',
            content: <GlobalWrap><GrantRoles form={grantUserForm} user={user} /></GlobalWrap>,
            onOk: (close) => {
                grantUserForm.validateFields().then(async values => {
                    var resp = await jsonRequest.post(`/api/user/${user.id}/roles`, values)

                    if (resp.ok) {
                        message.success("设置成功完成")
                        grantUserForm.resetFields()
                        handleLoad()
                        close()
                    } else {
                        message.error("创建失败")
                    }
                })


                return true
            },
        })
    }

    const handleUpdateUserStatus = async (user_id: string, status: string) => {
        if ((await jsonRequest.post(`/api/user/${user_id}/status`, { status })).ok) {
            handleLoad()
        } else {
            message.error("修改状态失败")
        }
    }

    const statusButton = (user: any) => {
        switch (user.status) {
            case "normal":
                return <Button size="small" type="link" danger onClick={() => handleUpdateUserStatus(user.id, "ban")}>禁用</Button>

            case "ban":
                return <Button size="small" type="link" onClick={() => handleUpdateUserStatus(user.id, "normal")}>启用</Button>

            default:
                break;
        }
    }

    return <div className="page operator-user">
        <h1>操作人 <Button onClick={() => handleCreateUser()} type="link" size="small" icon={<PlusCircleTwoTone />}>添加操作人</Button></h1>

        <div className="content">
            <List dataSource={data.entries}
                loading={loading}
                rowKey={d => d.meta}
                grid={{ gutter: 16, column: 4 }}
                itemLayout="vertical"
                size="large"
                renderItem={(item: any) => (
                    <List.Item key={item.id} className="item-operator" style={{ opacity: item.status == "ban" ? 0.5 : 1 }}
                        extra={<div>
                            <Button size="small" type="link" disabled>重置密码</Button>
                            <Divider type="vertical" />
                            <Button size="small" type="link" onClick={() => { handleGrantRoles(item) }}>授予角色</Button>
                            <Divider type="vertical" />
                            {statusButton(item)}
                        </div>}>
                        <div className="name"><UserOutlined /> {item.name}</div>
                        <div> <span><Tooltip title="密码登录" color="orange">{item.evidence_password && <KeyOutlined style={{ color: "orange" }} />}</Tooltip></span></div>
                    </List.Item >
                )}>

            </List>
            <Pagination size="small" total={data.total_entries} pageSize={data.page_size} current={data.page_number}
                onChange={(page, size) => handleLoad(page, size)}></Pagination>
        </div>
    </div >
}