import {ActionType, ProTable} from "@ant-design/pro-components";
import {selectCurrentTenant, selectJsonRequest} from "../../components/global";
import {Button, Divider, Form, message, Modal, Popconfirm, Tag} from "antd";
import GlobalWrap from "../../components/globalWrap";
import {PlusOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import SystemForm from "./components/SystemForm";
import {useEffect, useRef} from "react";

export default function System(params: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [form] = Form.useForm();
    const tableRef = useRef<ActionType>();

    useEffect(() => {
        tableRef.current?.reload();
    }, [currentTenant]);

    const columns: any[] = [
        {
            title: '名称',
            dataIndex: 'systemName',
            align: 'center',
        },
        {
            title: '编码key',
            dataIndex: 'systemCode',
            search: false,
            copyable: true,
            align: 'center',
        },
        {
            title: '是否关联当前租户',
            dataIndex: 'ownerStatus',
            search: false,
            align: 'center',
            width: 200,
            render: (_: any, record: any) => <Tag
                color={record.ownerStatus ? 'success' : 'error'}>{record.ownerStatus ? '是' : '否'}</Tag>,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            search: false,
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            key: 'option',
            search: false,
            align: 'center',
            width: 300,
            render: (_: any, record: any) => <>
                <Button type="link" onClick={() => handleAddOrEdit(record)}>修改</Button>
                <Button type="link"
                        onClick={() => handleTenant(record)}>{record.ownerStatus ? '取消关联当前租户' : '关联当前租户'}</Button>
                {/*<Popconfirm title="嗯？" okText="嗯！" cancelText="嗯~" onConfirm={() => onDelete(record.id)}>*/}
                {/*    <Button type="link" danger>删除</Button>*/}
                {/*</Popconfirm>*/}
            </>,
        }
    ];

    const onDelete = async (id: string) => {
        const {data} = await jsonRequest.post("/api/request", {
            path: `/v1/systems/${id}`,
            method: "delete",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {}
        }).then(resp => resp.json())

        if (!data.error) {
            // @ts-ignore
            tableRef.current.reload();
            message.success(`删除成功`)
        } else {
            message.error(data.error);
        }
    }

    const handleTenant = async ({id, ownerStatus}: any) => {
        await jsonRequest.post("/api/request", {
            path: `/v1/systems/tenant`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {id, refStatus: ownerStatus ? 0 : 1},
        }).then(resp => resp.json())

        // @ts-ignore
        tableRef.current.reload();
        message.success(`操作成功`)
    }

    const handleAddOrEdit = async (detail: any) => {
        Modal.confirm({
            width: 600,
            title: <span>知识体系 - {detail?.id ? '编辑' : '新增'}</span>,
            content: <GlobalWrap><SystemForm form={form} detail={detail}/></GlobalWrap>,
            onOk: (close) => {
                form.validateFields()
                    .then(async (values) => {
                        console.log(values)
                        const {data} = await jsonRequest.post("/api/request", {
                            path: `/v1/systems`,
                            method: detail.id ? "put" : "post",
                            application_key: "zy:application::knowledge-foundation",
                            tenant_meta: currentTenant,
                            data: values,
                        }).then(resp => resp.json())
                        if (!data.error) {
                            // @ts-ignore
                            tableRef.current.reload();
                            message.success(`${detail?.id ? '编辑' : '新增'}成功`)
                            close();
                        } else {
                            message.error(data.error);
                        }
                    })
                    .catch(_ => _)
                return false
            },
            onCancel: (close) => {
                form.resetFields();
                close();
            },
        })
    }

    const request = async (params: any, sort: any, filter: any) => {
        const {current, pageSize, systemName} = params;
        const result = await jsonRequest.post("/api/request", {
            path: "/v1/systems/paged",
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {
                keyword: systemName,
                pageNumber: current,
                pageSize,
            },
        }).then((resp: any) => resp.json())
        const {data} = result;
        return {data: data.list, success: result.code === 0, total: data.total};
    }

    return (
        <>
            <h1>知识体系维护</h1>
            <ProTable
                rowKey="id"
                actionRef={tableRef}
                cardBordered
                columns={columns}
                request={request}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                search={{span: 6, defaultCollapsed: false}}
                toolBarRender={() => [<Button
                    key="button"
                    icon={<PlusOutlined/>}
                    onClick={() => handleAddOrEdit({})}
                    type="primary"
                >
                    新增
                </Button>]}
            />
        </>
    )
}