import OrderedMedia from "./OrderedMedia";
import {Form, Input} from "antd";
import CustomQuill from "../../../components/CustomQuill";
import ContentImageUploader from "../../components/ContentImageUploader";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";

export default function HistoryInput({prefix, index, form, listPathName}: any) {

    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)
    
    const imgUploadApi = async (fd: any) => {
        return await ContentImageUploader(jsonRequest, currentTenant, fd.get('file'));
    }

    return <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <Form.Item label='标题' name={[...prefix, 'title']}>
            <Input/>
        </Form.Item>
        <Form.Item label='作者' name={[...prefix, 'author']}>
            <Input/>
        </Form.Item>
        <Form.Item label='朝代' name={[...prefix, 'dynasty']}>
            <Input/>
        </Form.Item>
        <Form.Item label='时间' name={[...prefix, 'times']}>
            <Input/>
        </Form.Item>
        <Form.Item label='详情' name={[...prefix, 'description']}>
            <CustomQuill imgUploadApi={imgUploadApi}/>
        </Form.Item>
    </OrderedMedia>
}