import RichTextEditor from 'quill-react-commercial';
import 'quill-react-commercial/lib/index.css';
import {useRef, useState} from "react";

export default function CustomQuill({
                                        placeholder = "请输入...",
                                        imgUploadApi,
                                        value,
                                        onChange,
                                        quillRef,
                                        ...others
                                    }: any) {
    const quill: any = useRef();
    const [hasUpdate, setHasUpdate]: any = useState(false)

    const modules = {
        table: {},
        codeHighlight: true,
        imageHandler: {imgUploadApi},
    }

    const onEditorChange = (v: any) => {
        setHasUpdate(true)
        onChange && onChange(quill.current.getSemanticHTML(), quill.current.getText())
    }

    const getQuill = (quillIns: any) => {
        quill.current = quillIns;
        quill.current.blur();
        quillRef && (quillRef.current = quillIns);
    }

    return <RichTextEditor modules={modules} placeholder={placeholder} i18n='zh'
                           {...others} onChange={onEditorChange} getQuill={getQuill}
                           content={hasUpdate ? undefined : value}/>
}