

import { useSelector } from "react-redux"
import "./TestClient.css"
import { selectCurrentTenant, selectUser } from "../components/global"
import configFunc from "../config"
export default function TestClient(params: any) {
    const user = useSelector(selectUser)
    const currentTenant = useSelector(selectCurrentTenant);


    return <div className="page customer-service test-client">
        <iframe src={`${configFunc.HttpEndpoint("/api/customer-service/test-client")}?token=${user.token}&tenant_meta=${currentTenant}`}>

        </iframe>
    </div>
}