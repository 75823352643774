import React, { useEffect, useRef } from "react";
import { Form, Input, InputRef, Select } from "antd";
import { FormInstance } from "rc-field-form/lib/interface";

export const EditableCell: React.FC<{
  record: any;
  inputType: string;
  dataIndex: string;
  title: string;
  releaseChannels?: [];
  editing: string[];
  setEditing: Function;
}> = ({
  record,
  inputType,
  dataIndex,
  title,
  releaseChannels,
  editing,
  setEditing,
}: any) => {
  const inputRef = useRef<InputRef>(null);
  const isEditing = editing.includes(record.id);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const toggleEdit = () => {
    setEditing([record.id, ...editing]);
  };

  const validator = (form: FormInstance) => {
    let required = true;
    if (
      !form.getFieldValue(`releaseLogisticsId,${record.id}`) &&
      !form.getFieldValue(`expressNo,${record.id}`)
    ) {
      required = false;
    }

    return {
      required,
      message: inputType === "text" ? `${title} 是必填的` : `${title} 是必选的`,
    };
  };

  if (!isEditing) {
    return (
      <div
        style={{ width: "100%", height: "22px", color: "#ddd" }}
        onClick={toggleEdit}
      >
        {inputType === "text" ? "点击输入" : "点击选择"}
      </div>
    );
  }

  return (
    <Form.Item
      style={{ margin: 0 }}
      name={`${dataIndex},${record.id}`}
      rules={[validator]}
    >
      {inputType === "selection" ? (
        <Select>
          {releaseChannels.map((channel: any) => (
            <Select.Option key={channel.id} value={channel.id}>
              {channel.channelName}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Input ref={inputRef} />
      )}
    </Form.Item>
  );
};
