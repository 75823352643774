import {Button, Divider, Form, Input, Radio, Select, Image, message, DatePicker, InputNumber, Row, Col} from "antd";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../components/global";
import orderStatus from "./order_status";
import {CopyTwoTone, FieldTimeOutlined, MoneyCollectOutlined, MoneyCollectTwoTone} from "@ant-design/icons";
import "./ShippingForm.css"
import dayjs from "dayjs";
import ExpressInfo from "./ExpressInfo";

export default function ShippingForm({order, form}: any) {
    const [releaseChannels, setReleaseChannels]: any = useState([])
    const currentTenant = useSelector(selectCurrentTenant)

    const jsonRequest = useSelector(selectJsonRequest)
    useEffect(() => {
        jsonRequest.post("/api/request", {
            path: "/v1/releaseChannel/",
            method: "get",
            application_key: "zy:services::mall",
            tenant_meta: currentTenant,
            data: {}
        })
            .then(resp => resp.json())
            .then(({data}: any) => {
                setReleaseChannels(data)
            })
    }, [])

    form.setFieldsValue({
        ...order.shippingManifest,
        logisticsCreateAt: order.shippingManifest?.logisticsCreateAt ? dayjs(order.shippingManifest?.logisticsCreateAt) : dayjs(),
        logisticsSettlementAt: order.shippingManifest?.logisticsSettlementAt ? dayjs(order.shippingManifest?.logisticsSettlementAt) : null,
        releaseLogisticsId: order.shippingManifest?.releaseChannel?.id
    })

    const handleCopyFullAddress = (address: string) => {
        navigator.clipboard.writeText(address)
            .then(() => {
                message.success({content: '已复制到剪切板中', key: "exporting", duration: 3})
            })
            .catch(() => {
                message.error({content: '写入剪切板错误', key: "exporting", duration: 3})
                navigator.clipboard.writeText("")
            })

    }

    const fullAddress = `${order.address?.fullAddress}, ${order.address?.address}, ${order.address?.fullname}, ${order.address?.phone}`

    return <div className="order-shipping-form">
        <Form form={form} size="small" name="basic" labelCol={{span: 6}} wrapperCol={{span: 16}} autoComplete="off"
              style={{display: "flex"}}>
            <div style={{flex: 1}}>
                <Divider>买家信息</Divider>
                <Form.Item label="完整地址">
                    {fullAddress} <Button type="link" size="small" icon={<CopyTwoTone/>}
                                          onClick={() => handleCopyFullAddress(fullAddress)}>点击复制</Button>
                </Form.Item>
                <Row gutter={2}>
                    <Col span={12}>
                        <Form.Item label="姓名" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.fullname}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="电话" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.phone}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={2}>
                    <Col span={12}>
                        <Form.Item label="省" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.province}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="市" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.city}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={2}>
                    <Col span={12}>
                        <Form.Item label="区" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.district}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="街道" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.street}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={2}>
                    <Col span={12}>
                        <Form.Item label="地址" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.address}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="买家留言" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                            {order.address?.buyerMessage || "无"}
                        </Form.Item>
                    </Col>
                </Row>
                {order.shippingManifest && <>
                    <Divider>物流信息</Divider>
                    <ExpressInfo id={order.shippingManifest.id} maxHeight={300}/>
                </>}
            </div>
            <div style={{flex: 1}}>
                <Divider>订单信息</Divider>
                <Form.Item name='id' hidden><Input disabled/></Form.Item>
                <Form.Item label="订单状态">
                    {orderStatus(order.status)}
                </Form.Item>
                <Form.Item label="下单时间">
                    <FieldTimeOutlined
                        style={{color: "rgb(22, 119, 255)"}}/> {dayjs(order.createAt).format("YYYY-MM-DD HH:mm")}
                </Form.Item>
                <Form.Item label="订单金额">
                    <MoneyCollectTwoTone/> {order.totalAmount}
                </Form.Item>
                <Form.Item label="订单详情">
                    <div className="order-details">
                        {order.extendInfo.map((detail: any) =>
                            <div className="sku-item" key={detail.id}>
                                <div>
                                    <Image className="img" src={detail.productSku?.images?.[0].objName} width={80}/>

                                    &nbsp;
                                    <span> {detail.productTitle}</span>
                                </div>

                                <span style={{color: "#aaa"}}>x {detail.quantity}</span>
                            </div>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label="物流公司" name="releaseLogisticsId" rules={[{required: true, message: '不能为空'}]}>
                    <Select>
                        {releaseChannels.map((channel: any) =>
                            <Select.Option key={channel.id} value={channel.id}>{channel.channelName}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="物流单号" name="expressNo" rules={[{required: true, message: '不能为空'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="物流状态" name="logisticsStatus" initialValue={100}
                           rules={[{required: true, message: '不能为空'}]}>
                    <Select disabled>
                        <Select.Option value={100}>配送中</Select.Option>
                        <Select.Option value={200}>已送达</Select.Option>
                        <Select.Option value={300}>投送异常</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="发货时间" name="logisticsCreateAt">
                    <DatePicker format="YYYY-MM-DD HH:mm:ss"/>
                </Form.Item>
                {/*<Form.Item label="物流结算时间" name="logisticsSettlementAt">*/}
                {/*    <DatePicker format="YYYY-MM-DD HH:mm:ss"/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label="物流成本金额" name="deliveryAmount">*/}
                {/*    <InputNumber/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label="物流发货运费" name="logisticsFee">*/}
                {/*    <InputNumber/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label="物流支付单号" name="logisticsPayExpressNo">*/}
                {/*    <Input/>*/}
                {/*</Form.Item>*/}
            </div>
        </Form>
    </div>
}