import {Flex, Image, Space} from "antd";
import React from "react";

export default function VideoPreview({pictureName, description, picturePath, videoPath}: any) {

    return <Flex align='center' justify='left'>
        <Image width={80} src={picturePath} preview={{
            destroyOnClose: true,
            imageRender: () => <video controls src={videoPath}/>,
            toolbarRender: () => null,
        }}/>
        <Space direction='vertical' style={{marginLeft: '20px'}}>
            <b>{pictureName}</b>
            <div style={{maxWidth: '400px', textWrap: 'wrap'}}>{description}</div>
        </Space>
    </Flex>
}