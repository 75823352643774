import {useState, useEffect} from "react";
import {Flex, Upload} from "antd";

import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../components/global";
import Sha256Encode from "../../components/sha256";
import OssPath from "../../components/oss_path";

export default function KnowledgeUploader({type, value, onChange, children, ...props}: any) {
    const [OSSData, setOSSData]: any = useState();

    const jsonRequest: any = useSelector(selectJsonRequest)
    const currentTenant: any = useSelector(selectCurrentTenant)

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        jsonRequest.post("/api/request", {
            path: `/v1/aliyun/oss`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {type}
        })
            .then((resp: any) => resp.json())
            .then(({data}: any) => setOSSData(data))
    };

    const handleChange = async ({fileList}: any) => {
        const [file] = fileList;
        if (!file) {
            onChange?.([]);
            return;
        }

        const ossPath = await OssPath(jsonRequest, currentTenant);
        file.url = `${ossPath}${file.url}`;
        onChange?.([file]);
    };

    const onRemove = (file: any) => {
        const files = (value || []).filter((v: any) => v.url !== file.url);

        // if (onChange) {
        //     onChange(files);
        // }
    };

    const getExtraData = (file: any) => ({
        key: file.url,
        OSSAccessKeyId: OSSData?.keyId,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
    });

    const beforeUpload = async (file: any) => {
        if (!OSSData) return false;

        const expire = Number(OSSData.expire) * 1000;

        if (expire < Date.now()) {
            await init();
        }

        const file_content = await file.arrayBuffer()
        const file_name = await Sha256Encode(file_content)

        file.url = [OSSData?.dir, file_name].join("/")
        return file;
    };

    const uploadProps = {
        // name: 'file',
        fileList: value,
        action: OSSData?.host,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        beforeUpload,
    };

    return (
        <Upload {...props} {...uploadProps}>
            {children}
        </Upload>
    );
};
