import {Form, Input} from "antd";
import React from "react";
import PictureInput from "./PictureInput";
import OrderedMedia from "../../content/components/OrderedMedia";

export default function MediaPictureInput({
                                              form,
                                              prefix = [],
                                              fullPrefix = [],
                                              field,
                                              index, listPathName,
                                          }: any) {
    const {isRequired} = field || {};

    const InputBody = () => <>
        <Form.Item label={`图片名称`} name={[...prefix, "pictureName"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入图片名称"/>
        </Form.Item>
        <Form.Item label={`图片描述`} name={[...prefix, "description"]}
                   rules={[{required: isRequired, message: '不能为空'}]}>
            <Input placeholder="请输入图片描述"/>
        </Form.Item>
        <PictureInput form={form} prefix={prefix} fullPrefix={fullPrefix} field={field}
                      fileNamePath={[...fullPrefix, 'pictureName']}/>
    </>

    return listPathName ? <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <InputBody/>
    </OrderedMedia> : <InputBody/>
}