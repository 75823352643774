import {Button, Form, Input, Space, Image, Modal} from "antd";
import GlobalWrap from "../../../components/globalWrap";
import CompareForm from "./CompareForm";
import React from "react";
import OrderedMedia from "./OrderedMedia";

export default function CompareInput({form, prefix, fullPrefix, index, listPathName}: any) {
    const [compareForm] = Form.useForm();
    let modal: any;
    const equalInit = '<p>相同点</p>';
    const diffInit = [
        {name: '<p>中药</p>', diff: '<p>不同点</p>'},
        {name: '<p>中药</p>', diff: '<p>不同点</p>'},
    ]

    const onModalOk = async (values: any) => {
        const origin = form.getFieldValue(fullPrefix)
        form.setFieldValue(fullPrefix, {...origin, ...values})
        modal.destroy();
    }

    const onModalCancel = () => {
        modal.destroy();
    }

    const handleModel = () => {
        const init = form.getFieldValue(fullPrefix)
        modal = Modal.confirm({
            width: 1200,
            title: '鉴别用药',
            okButtonProps: {loading: false},
            content: <GlobalWrap>
                <CompareForm form={compareForm} init={init} onOk={onModalOk} onCancel={onModalCancel}/>
            </GlobalWrap>,
            footer: null,
        })
    }

    return <OrderedMedia index={index} form={form} listPathName={listPathName}>
        <Form.Item label='标题' shouldUpdate>
            {({getFieldValue}) => (<span>{getFieldValue([...fullPrefix, 'title'])}</span>)}
        </Form.Item>
        <Form.Item label={`效果图`} shouldUpdate>
            {({getFieldValue}) => {
                const src = getFieldValue([...fullPrefix, 'img_url'])
                return <Space align='start'>
                    {src && <Image src={src} width={300} height={100}/>}
                    <Button type='link' onClick={handleModel}>编辑</Button>
                </Space>
            }}
        </Form.Item>
        <Form.Item name={[...prefix, 'title']} hidden initialValue={''}><Input/></Form.Item>
        <Form.Item name={[...prefix, 'img_url']} hidden initialValue={''}><Input/></Form.Item>
        <Form.Item name={[...prefix, 'equal']} hidden initialValue={equalInit}><Input/></Form.Item>
        <Form.Item name={[...prefix, 'diff']} hidden initialValue={diffInit}><Input/></Form.Item>
    </OrderedMedia>
}