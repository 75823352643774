import {Form, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentTenant, selectJsonRequest} from "../../../components/global";
import MediaList from "../../content/components/MediaList";

export default function CategoryDetailForm({form, detail}: any) {
    const jsonRequest = useSelector(selectJsonRequest)
    const currentTenant = useSelector(selectCurrentTenant)
    const [contentFields, setContentFields]: any = useState([])

    useEffect(() => {
        getContentMedias();
    }, []);
    
    const getContentMedias = async () => {
        await jsonRequest.post("/api/request", {
            path: `/v1/contents/medias/noneTemp`,
            method: "post",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode: detail?.systemCode, ownerType: 'content_cate', ownerId: detail?.id},
        }).then((resp: any) => resp.json())
        
        jsonRequest.post("/api/request", {
            path: `/v1/contents/medias/content_cate/${detail.id}`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                console.log('content medias', data)
                if (data.length === 0) {
                    getTempMedias(detail.systemCode)
                } else {
                    setContentFields(data)
                    form.setFieldsValue({medias: data})
                }
            })
    }

    const getTempMedias = (systemCode: any) => {
        jsonRequest.post("/api/request", {
            path: `/v1/system-fields/temp/medias`,
            method: "get",
            application_key: "zy:application::knowledge-foundation",
            tenant_meta: currentTenant,
            data: {systemCode, fieldType: 'content_cate_info', ownerId: detail.id},
        }).then((resp: any) => resp.json())
            .then(({data}) => {
                console.log('temp medias', data)
                setContentFields(data)
                form.setFieldsValue({medias: data})
            })
    }
    
    return <Form form={form} size="middle" name="basic" style={{maxHeight: '700px', overflowY: 'scroll'}}
                 labelCol={{span: 2}} wrapperCol={{span: 20}} className='medias-form'
                 initialValues={{ownerId: detail.id, ownerType: 'content_cate'}}>
        <Form.Item label="ownerId" name="ownerId" hidden><Input disabled/></Form.Item>
        <Form.Item label="ownerType" name="ownerType" hidden><Input disabled/></Form.Item>
        <Form.Item label="知识体系">{detail.systemName}</Form.Item>
        <Form.Item label="所属分组">{detail.fieldName}</Form.Item>
        <Form.Item label="上级分类">{detail.parentName}</Form.Item>
        <Form.Item label="当前分类">{detail.cateName}</Form.Item>
        <MediaList form={form} fields={contentFields}/>
    </Form>
}