import { PlusCircleTwoTone, EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectJsonRequest } from "../../components/global";
import GlobalWrap from "../../components/globalWrap";

export default function ApplicationList(params: any) {
    const [data, setData]: any = useState([])
    const [loading, setLoading] = useState(true)
    const jsonRequest = useSelector(selectJsonRequest)
    const [applicationForm] = Form.useForm()

    useEffect(() => {
        handleLoad()

        return () => { }
    }, [])

    const handleLoad = async () => {
        const responseJson = (await jsonRequest.get("/api/application", {})).json()
        setData((await responseJson).data)
        setLoading(false)
    }


    const handleSaveApplication = (item: any) => {
        applicationForm.setFieldsValue(item)
        Modal.confirm({
            width: 400,
            title: '录入应用服务',
            content: <GlobalWrap>
                <Form form={applicationForm} name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
                    <Form.Item label="应用服务名" name="name" rules={[{ required: true, message: '不能为空' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="应用KEY" name="key" rules={[{ required: true, message: '不能为空' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="协议" name="protocol" rules={[{ required: true, message: '不能为空' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="主机端口" name="host" rules={[{ required: true, message: '不能为空' }]}>
                        <Input />
                    </Form.Item>

                </Form>
            </GlobalWrap>,
            onOk: async (close) => {
                let values = await applicationForm.validateFields()

                if ((await jsonRequest.put("/api/application", { ...values, id: item?.id })).ok) {
                    message.success("保存完成")
                    applicationForm.resetFields()
                    handleLoad()
                    close()
                } else {
                    message.error("创建失败")
                }

                return true
            },
        })
    }


    return <div className="page mall-order">
        <h1>应用服务列表 <Button onClick={() => handleSaveApplication(null)} type="link" size="small" icon={<PlusCircleTwoTone />}>录入应用服务</Button></h1>

        <div className="content">

            <Table dataSource={data} loading={loading} pagination={false}>
                <Table.Column align="left" title="应用服务名" dataIndex="name" key="name" />
                <Table.Column align="left" title="KEY" dataIndex="key" key="key" />
                <Table.Column align="left" title="协议" dataIndex="protocol" key="protocol" />
                <Table.Column align="left" title="主机名端口" dataIndex="host" key="host" />
                <Table.Column align="center" title="操作" render={(text, item) => {
                    return <>
                        <Button type="link" icon={<EditOutlined />} onClick={() => handleSaveApplication(item)}>编辑</Button>
                    </>
                }} />
            </Table>
        </div>
    </div >
}