import { Menu } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";
// import RequireRolesOr from '../../components/require_roles_or'

function SideMenu() {
    const location = useLocation()

    const pathName = location.pathname.split("/").slice(0, 3).join("/")
    const fullPathName = location.pathname

    return <Menu style={{ width: 256 }} defaultSelectedKeys={[pathName, fullPathName]} mode="inline" >
        {/* <RequireRolesOr require={["acupoint:role::operator", "acupoint:role::finance"]}> */}
        <Menu.ItemGroup key="jose" title="JOSE 签名加密">
            <Menu.Item key="/tool/jose/signature" icon={<UnorderedListOutlined />}>
                <Link to="/tool/jose/signature">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>签名</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="/tool/jose/encryption" disabled icon={<UnorderedListOutlined />}>
                <Link to="/tool/jose/signature">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>加密</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu >
}



export default SideMenu