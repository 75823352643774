import { Form, Input, Select, InputNumber } from "antd";
import "./ShippingForm.css"

export default function OrderRefundForm({ order, form }: any) {

    form.setFieldsValue({
        refundAmount: order.paymentAmount - order.refundAmount,
        refundType: 0
    })

    return <div className="order-shipping-form">
        <Form form={form} size="small" name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="订单总金额">
                {order.totalAmount}
            </Form.Item>
            <Form.Item label="订单已支付金额">
                {order.paymentAmount}
            </Form.Item>
            <Form.Item label="订单已退款金额">
                {order.refundAmount}
            </Form.Item>
            <Form.Item label="退款类型" name="refundType" rules={[{ required: true, message: '不能为空' }]}>
                <Select>
                    <Select.Option value={0}>退货退款</Select.Option>
                    <Select.Option value={1}>没收到货退款</Select.Option>
                    <Select.Option value={2}>收到货退款</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="退款金额" name="refundAmount" rules={[{ required: true, message: '不能为空' }]}>
                <InputNumber max={order.paymentAmount - (order.refundAmount || 0)} min={1} />
            </Form.Item>
            <Form.Item label="退款原因" name="remark" rules={[{ required: true, message: '不能为空' }]}>
                <Input.TextArea maxLength={255} />
            </Form.Item>
        </Form>
    </div>
}